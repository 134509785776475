<template>
    <div>
        <textarea class="form-control" rows="4" v-model="item.note"></textarea>
    </div>
</template>

<script>
    export default {
        data: () => ({

        }),
        components: {},
        computed: {},
        props: ['item'],
        methods: {

        },
        created() {

        },
        mounted() { }
    };
</script>