<template>
    <b-card-body class="pt-0">
        <div class="row">

            <div class="col-md-12 mb-2">
                <EditorSelect :item="field('5d8a057dfa22d0204498933d')" :entity="entity" scope="module" :validate="true" internal="Producer" />
            </div>

            <div class="col-md-6">
                <EditorSelect :item.sync="statusField" :entity="entity" scope="module" :validate="false" internal="Status" />
            </div>
            <div class="col-md-6">
                <EditorSelect :item="field('5d8a0355fa22d02044989332')" :entity="entity" scope="module" :validate="true" internal="Order type" />
            </div>
            <div class="col-md-6">
                <EditorDate :item="field('5d8a04aefa22d02044989338')" :entity="entity" scope="module" :validate="false" internal="Usage date (internal)" />
            </div>
            <div class="col-md-6">
                <EditorDate :item="field('5d8a04bafa22d02044989339')" :entity="entity" scope="module" validate="minDate:5d8a04aefa22d02044989338" internal="Shipping deadline" />
            </div>
            <div class="col-md-6">
                <EditorSelect :item="field('5d8a03cefa22d02044989335')" :entity="entity" scope="module" :validate="false" internal="Store" />
            </div>
            <div class="col-md-6">
                <EditorSelect :item="field('5d8a039ffa22d02044989334')" :entity="entity" scope="module" :validate="false" internal="Purchase reason" />
            </div>
            <div class="col-md-6">
                <EditorSelect :item="field('627ccd32f47c413048c3b872')" :entity="entity" scope="module" :validate="false" internal="Own/others reason" />
            </div>
            <div class="col-md-6">
                <EditorSelect :item="field('5d8a04ecfa22d0204498933c')" :entity="entity" scope="module" :validate="false" internal="Special order (internal)" />
            </div>
            <div class="col-md-6">
                <EditorReadonly :item="field('5d8a0389fa22d02044989333')" :entity="entity" scope="module" :validate="false" internal="Invoice number" />
            </div>
            <div class="col-md-6">
                <EditorReadonly :item="field('5d8a0498fa22d02044989337')" :entity="entity" scope="module" :validate="false" internal="Payment status" />
            </div>
            <div class="col-md-6">
                <EditorSelect :item="field('5d8a059dfa22d0204498933f')" :entity="entity" scope="module" :validate="false" internal="Installment Payments" />
            </div>
            <div class="col-md-6">
                <EditorRichText :item="field('5d8a04c3fa22d0204498933a')" :entity="entity" scope="module" :validate="false" internal="Invoice note" />
            </div>
        </div>
    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";
import Editors from "../../mixins/editors";

import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    data: () => ({}),
    inject: ['$validator'],
    components: { ...Editors, ValidationProvider, ValidationObserver },
    computed: {
        usageDate() {
            return this.entity["5d8a04aefa22d02044989338"];
        },
        shippingDeadline() {
            return this.entity["5d8a04bafa22d02044989339"];
        },
        producer() {
            return this.entity["5d8a057dfa22d0204498933d"];
        },
        statusField() {

            var field = Object.assign({}, this.field('5d8a0452fa22d02044989336'))

            var entityValue = this.entity["5d8a0452fa22d02044989336"]

            if (entityValue != "Wait for approval" && entityValue != null && entityValue != "") {
                field.values = field.values.filter(x => x != "Wait for approval");
            }

            return field
        }
    },
    watch: {
        producer() {
            this.checkProducers();
        }
    },
    methods: {
        checkProducers() {
            if (!this.entity.orderLines) {
                return []
            }

            var orderlines = this.entity.orderLines.filter(x => x.brand != null && x.brand != "").map(x => x.brand);
            var brands = [...new Set(orderlines)];
            
            if (brands.length > 0) {
                var unmatching = brands.find(x => this.producer != x);
                if (unmatching) {
                    if (!this.errors.has("5d8a057dfa22d0204498933d", "module")) {
                        this.errors.add({
                            field: '5d8a057dfa22d0204498933d',
                            msg: 'De valgte produkter og producenten passer ikke sammen!',
                            scope: 'module'
                        });
                    }
                } else {
                    this.errors.remove("5d8a057dfa22d0204498933d", "module");
                }
            } else {
                this.errors.remove("5d8a057dfa22d0204498933d", "module");
            }

            return brands;
        }
    },
    created() {
        this.checkProducers();
    },
    mounted() {},
    mixins: [mixin]
};
</script>
