import "./style.scss";

import Vue from 'vue'
const routes = [];
const RouterView = { render (c) { return c('router-view') } };

import details from "./areas/details";
Vue.component("usersDetails", details);

import Users from "./users";
import User from "./user";

routes.push({ path: "users", component: RouterView, meta: { label: "Brugere" }, children: [
    { path: "", component: Users, name: "users", meta: { module: "users" } },
    { path: ":id", component: User, name: "user", meta: { label: "Bruger", module: "users" } }
] });

import store from './store';

export { routes, store };
