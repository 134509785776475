import Vue from "vue"
import Vuex from 'vuex'
import axios from "axios"
import router from "./router"
import store from "./store"
import App from "./views/root"
import "./components"
import "./mixins"

import "./scss/style.scss"
import "@fortawesome/fontawesome-free/css/all.css"
import "vue-multiselect/dist/vue-multiselect.min.css"


import BootstrapVue from "bootstrap-vue"
Vue.use(BootstrapVue)

//import moment from "moment"
import moment from "moment-timezone"
moment.locale("da")
moment.tz.setDefault("Europe/Berlin");

import numeral from "numeral"
import "numeral/locales/da-dk"
numeral.locale("da-dk")

import da from "vee-validate/dist/locale/da"
import VeeValidate, { Validator } from "vee-validate"
Vue.use(VeeValidate, {
    locale: "da",
    fieldsBagName: "veeFields"
})
Validator.localize("da", da)



const validator = {
    getMessage(field, [inputName]) {
        var otherInput = document.querySelector("input[name='" + inputName + "']")

        var otherField = otherInput.dataset.vvAs
        if (!otherField) {
            otherField = "den valgte dato."
        }
        return field + " skal være før " + otherField
    },
    validate(value, [inputName]) {
        var otherInput = document.querySelector("input[name='" + inputName + "']")
        var otherVal = otherInput.value

        if (!otherVal || !value) {
            return true
        }

        var otherDate = moment(otherVal)
        var date = moment(value)

        if (date >= otherDate) {
            return false
        }

        return true
    }
}
Validator.extend("minDate", validator, {
    hasTarget: true,
    immediate: false
})

// import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
// Vue.component('Validation', ValidationProvider)
// Vue.component('ValidationForm', ValidationObserver)


import { ServerTable } from "vue-tables-2"
Vue.use(ServerTable)


import VueMasonry from "vue-masonry-css"
Vue.use(VueMasonry)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
VueCookies.config('7d')

import CKEditor from '@ckeditor/ckeditor5-vue'
Vue.use(CKEditor)

import fileDownload from 'js-file-download'

Vue.prototype.$http = axios
Vue.prototype.$moment = moment
Vue.prototype.$numeral = numeral
Vue.prototype.$fileDownload = fileDownload


window.browserIntercept = function browserIntercept(e) {
    e.preventDefault()
    e.returnValue = ''
}

Vue.config.productionTip = false

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
