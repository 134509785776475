<template>
    <b-card-body class="pt-0">
        <div class="row">
            <div class="col-md-6">
                <EditorSelect :item="field('5d7b83232dce1817fc348a6f')" :entity="entity" scope="module" :validate="false" internal="Connection" />
            </div>
            <div class="col-md-6">
                <EditorSelect :item="field('5d7b8ab52dce1817fc348cfa')" :entity="entity" scope="module" :validate="false" internal="Gender" />
            </div>
            <div class="col-md-6">
                <EditorText :item="field('5d7b8ad22dce1817fc348cfb')" :entity="entity" scope="module" :validate="'required|email'" internal="E-mail" />
            </div>
            <div class="col-md-6">
                <EditorText :item="field('5d7b8ae52dce1817fc348cfc')" :entity="entity" scope="module" :validate="false" internal="Phone" sensitive />
            </div>
            <div class="col-md-6">
                <EditorSelect :item="field('5d7b8aff2dce1817fc348d00')" :entity="entity" scope="module" :validate="false" internal="Newsletter" />
            </div>
            <div class="col-md-6">
                <EditorSelect :item="field('5dd28ac565258f1a5c4eeef4')" :entity="entity" scope="module" :validate="false" internal="Receive e-mails" />
            </div>
            <div class="col-md-6">
                <EditorDate :item="field('627bc9b5fafcb6726419813a')" :entity="entity" scope="module" :validate="false" internal="Birthday" />
            </div>
            <div class="col-md-6">
                <EditorSelect :item="field('627ccbe0f47c413048c3adc9')" :entity="entity" scope="module" :validate="false" internal="Work apparel" />
            </div>
        </div>
    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";
import Editors from "../../mixins/editors";

export default {
    data: () => ({}),
    components: { ...Editors },
    computed: {},
    methods: {},
    created() {
    },
    mounted() {},
    mixins: [mixin]
};
</script>
