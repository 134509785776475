<template>
	<FormGroup :label="f.name || label" :alias="f.alias || alias" :name="name" 
		v-slot:default="props" :scope="scope" 
		v-if="(f.access || access) > 0">
		<b-form-input type="text" v-model="entity[props.alias]" 
			:state="props.state" :data-vv-scope="props.scope" 
			:disabled="(f.access || access) < 30"
			v-validate="validate ? 'required|decimal:2' : 'decimal:2'" 
			:name="name || props.alias" :data-vv-as="f.name || label" 
			autocomplete="off" @change="updateValue"></b-form-input>
	</FormGroup>
</template>

<script>
export default {
	data: () => ({
		f: {}
	}),
	inject: ['$validator'],
	components: {},
	computed: {
	},
	watch: {},
	props: {
		item: Object,
		entity: Object,
		label: String,
		scope: String,
		alias: String,
		name: String,
		validate: Boolean,
		access: {
			type: Number,
			default: 60
		},
		default: String
	},
	methods: {
		updateValue(val) {
			this.$emit("update", val);
		}
	},
	created() {
		if (this.item) {
			this.f = this.item;
		}

		var val = this.entity[this.f.alias || this.alias];
		if ((val == null || val == "") && this.default != null) {
			this.entity[this.f.alias || this.alias] = this.default;
		}
	},
	mounted() {}
};
</script>