<template>
    <div class="position-relative has-tool-menu stock-mgmt">
        <div class="tool-menu">
            <Breadcrumb />

            <div class="buttons">
                <ButtonAreas :entities="selected" />

                <b-button @click="exportData" variant="success"><span class="fa fa-file-download"></span> Export</b-button>
            </div>
        </div>
        
        <b-tabs content-class="" v-model="activeTab">
            <b-tab v-for="type in types" :title="'Lager (' + type + ')'" :key="type">

                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Gruppe (Book)</th>
                            <th>Navn (Fabric)</th>

                            <th v-for="company in companies" :key="'th-' + company"><span class="font-weight-normal">Producent</span> {{company}}</th>
                            <th>I alt</th>
                            <th class="text-right">Reserveret</th>
                            <th class="text-right">Note</th>
                            <th class="text-right" v-if="canEdit">
                                <button type="button" class="btn btn-success" @click="createStockItem"><i class="fa fa-plus"></i></button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in stockItems" :key="item.id">
                            <td>
                                <b-form-input v-model="item.group" :disabled="!canEdit"></b-form-input>
                            </td>
                            <td>
                                <b-form-input v-model="item.name" :disabled="!canEdit"></b-form-input>
                            </td>

                            <td v-for="(producer, ckey) in producers(item)" :key="producer.name">
                                <b-form-input v-model="producer.stockAmount" name="stock" :disabled="!canEdit"></b-form-input>
                            </td>

                            <td class="font-weight-bold align-middle">
                                {{$numeral(item.producers.reduce((sum, v) => sum + $numeral(v.stockAmount).value(), 0)).format("0.00")}}
                            </td>
                            
                            <td class="font-weight-bold align-middle text-right">
                                <button type="button" class="btn btn-link text-dark font-weight-bold font-md" @click="reserved(item)">
                                    <span>{{item.reservedAmount}}</span>
                                    <i class="fa fa-info-circle ml-1"></i>
                                </button>
                            </td>

                            <td class="text-right" width="150">
                                {{item.note}}
                            </td>

                            <td class="text-right" width="200" v-if="canEdit">
                                <button type="button" class="btn btn-primary mr-1" @click="saveStockItem(item)"><i class="fa fa-save"></i> Gem</button>
                                <button type="button" class="btn btn-danger mr-1" @click="deleteStockItem(item)"><i class="fa fa-trash"></i></button>
                                <button type="button" class="btn btn-secondary" @click="history(item)"><i class="fa fa-clock"></i></button>
                                <button type="button" class="btn btn-warning" @click="note(item)"><i class="fa fa-note-sticky"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </b-tab>


            <b-tab :title="'Forbrug (' + selectedType + ')'">

                
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Navn</th>
                            <th>
                                Forbrug pr. produkt
                            </th>
                            <th class="text-right">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in stockItems" :key="item.id">
                            <td>
                                <strong>{{item.name}}</strong>
                                <span class="d-block">{{item.group}}</span>
                            </td>
                            <td class="p-0">
                                <table class="table table-striped">
                                    <tbody>
                                        <tr v-for="(spend, key) in item.spend" :key="spend.productId + '-' + key">
                                            <td>
                                                <b-form-select :options="products" :value="getProductVal(spend)" @input="setProductVal($event, spend)" text-field="product" value-field="productId" :disabled="!canEdit"></b-form-select>
                                            </td>
                                            <td>
                                                <b-form-select :disabled="!getVariants(spend) || !canEdit" :options="getVariants(spend)" :value="getVariantVal(spend)" @input="setVariantVal($event, spend)" text-field="variant" value-field="variantId"></b-form-select>
                                            </td>
                                            <td>
                                                <b-form-input v-model="spend.spend" name="spend" :disabled="!canEdit"></b-form-input>
                                            </td>
                                            <td class="text-right" v-if="canEdit">
                                                <button type="button" class="btn btn-outline-danger" @click="item.spend.splice(key, 1)"><i class="fa fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td class="text-right">
                                <button v-if="canEdit" type="button" class="btn btn-success float-left" @click="item.spend.push({ product: null, productId: null, variant: null, variantId: null, spend: 0 })"><i class="fa fa-plus"></i></button>

                                <button v-if="canEdit" type="button" class="btn btn-primary" @click="saveStockItem(item)"><i class="fa fa-save"></i> Gem</button>
                                
                            </td>
                        </tr>
                    </tbody>
                </table>


            </b-tab>
        </b-tabs>


    </div>
</template>

<script>
    import historyModal from "./stockHistoryModal.vue";
    import reservedModal from "./stockReservedModal.vue";
    import noteModal from "./stockNoteModal.vue";

    export default {
        data: () => ({
            selected: [],
            products: [],
            companies: [],
            stockItems: [],
            types: ["Suit", "Lining", "Shirt"],
            activeTab: 0,
            selectedType: null
        }),
        components: {},
        computed: {
            canEdit() {
                return this.$store.state.client == "5c28dac9cd3408238446f08e" && this.$store.state.userRole == "admin"
            }
        },
        watch: {
            activeTab(val) {
                if (this.types[val]) {
                    this.selectedType = this.types[val]
                    this.getItems()
                }
            }
        },
        methods: {
            getProducts() {
                this.$http.get("/api/stock/products").then(x => {
                    this.products = x.data
                })
            },
            getItems() {
                this.$http.get("/api/stock?type=" + this.selectedType).then(x => {
                    this.stockItems = x.data
                })
            },
            getFields() {
                this.$http.get("/api/stock/fields").then(x => {
                    this.companies = x.data.companies
                })
            },
            createStockItem() {
                this.$http.post("/api/stock/create?type=" + this.selectedType, this.companies).then(x => {
                    this.getItems()
                })
            },
            saveStockItem(item) {
                this.$http.post("/api/stock/save", item).then(x => {
                    this.showSuccessMsg("Lager blev gemt!")

                    this.getItems()
                })
            },
            deleteStockItem(item) {
                if (confirm("Er du sikker på at du vil slette " + item.name + "?")) {
                    this.$http.post("/api/stock/delete/" + item.id).then(x => {
                        this.getItems()
                    })
                }
            },

            producers(item) {
                for (var i = 0; i < this.companies.length; i++) {
                    var company = this.companies[i]

                    var producer = item.producers.find(x => x.name == company)
                    if (!producer) {
                        item.producers.push({
                            name: company,
                            stockAmount: 0
                        })
                    }
                }

                return item.producers
            },

            getProductVal(spend) {
                var product = this.products.find(x => x.productId == spend.productId)

                if (product) {
                    return spend.productId
                }

                return null
            },
            setProductVal(e, spend) {
                var product = this.products.find(x => x.productId == e)

                spend.product = product.product
                spend.productId = product.productId
                spend.variant = null
                spend.variantId = null
            },


            getVariants(spend) {
                var product = this.products.find(x => x.productId == spend.productId)
                if (product && product.variants.length > 0) {
                    return product.variants
                }
                return null
            },
            getVariantVal(spend) {
                var variants = this.getVariants(spend)
                if (variants) {
                    var variant = variants.find(x => x.variantId == spend.variantId)
                    if (variant) {
                        return variant.variantId
                    }
                }
                return null
            },
            setVariantVal(e, spend) {
                var variants = this.getVariants(spend)
                var variant = variants.find(x => x.variantId == e)

                spend.variant = variant.variant
                spend.variantId = variant.variantId
            },
            
            reserved(item) {
			    this.$dialog.open(reservedModal, { stock: item }, { title: "Reserverede ordrer", okTitle: "Luk", okOnly: true, size: "lg" })
            },
            history(item) {
			    this.$dialog.open(historyModal, { id: item.id }, { title: "Lagerhistorik", okTitle: "Luk", okOnly: true, size: "lg" })
            },
            note(item) {
                var model = Object.assign({}, item)

                this.$dialog.open(noteModal, { item: model, }, {
                    title: "Note", okTitle: "Gem", okOnly: false, size: "md",
                    onOk: () => {
                        this.$http.post("/api/stock/save-note", model).then(x => {
                            item.note = model.note
                        })
                    },
                    onClose: () => {

                    }
                })
            },


            exportData() {
                this.$http({
                    url: '/api/stock/export?type=' + this.selectedType,
                    method: 'POST',
                    responseType: 'blob', // important
                }).then(x => {
                    this.$fileDownload(x.data, 'Stock.xlsx');
                });
            }
        },
        created() {
            this.selectedType = this.types[this.activeTab]
            this.getProducts()
            this.getFields()
            this.getItems()
        },
        mounted() { }
    };
</script>