<template>
	<router-view></router-view>
</template>

<script>
export default {
	data: () => ({}),
	components: {},
	computed: {},
	methods: {},
	async created() {
		await this.$store.dispatch("GET_USERS", this.$route);
	},
	mounted() {},
	beforeRouteEnter(to, from, next) {
		next(x => {
			x.$store.dispatch("CLEAR_MODULE_RULES");
			x.$store.dispatch("MODULE_RULES", { 
				module: to.meta.module, 
				menu: to.name 
			});
		});
	},
	beforeRouteUpdate(to, from, next) {
		if (to.meta.module != from.meta.module || to.name != from.name) {
			this.$store.dispatch("CLEAR_MODULE_RULES");
			this.$store.dispatch("MODULE_RULES", { 
				module: to.meta.module, 
				menu: to.name
			});
		}
		next();
	},
};
</script>