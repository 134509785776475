<template>
    <div>
        <b-card no-body class="mb-1" v-for="item in versions" :key="item.id">
            <b-card-header header-tag="header" class="">
                <div class="row">
                    <div class="col-sm-5" v-b-toggle="'accordion-' + item.id">
                        {{$moment(item.createDate).format("DD-MM-YYYY HH:mm")}}
                    </div>
                    <div class="col-sm-7 text-right">
                        {{getUser(item.userId)}}

                        <button v-b-toggle="'accordion-' + item.id" class="btn btn-info btn-sm ml-2" style="padding: 1px 6px;">
                            <span class="fa fa-ellipsis-h"></span>
                        </button>

                        <button @click="restoreVersion(item.id)" class="btn btn-warning btn-sm ml-1" style="padding: 1px 6px;">
                            <span class="fa fa-sync"></span>
                        </button>
                    </div>
                </div>
            </b-card-header>
            <b-collapse :id="'accordion-' + item.id" accordion="versions">
                <b-card-body>
                    <table>
                        <template v-for="(field, key) in changedFields(item)">
                            <tr :key="field.name + '-' + field.level + '-' + key">
                                <td :style="'padding-left: ' + field.level * 10 + 'px'" :class="{ 'py-2': field.spacer }">
                                    {{getField(field)}}
                                </td>
                                <td class="pl-4" :class="{ 'py-1': field.spacer }">
                                    {{getFieldValue(field)}}
                                </td>
                            </tr>
                        </template>
                    </table>
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
</template>

<script>
    export default {
        data: () => ({
            versions: [],
            mtmTypes: []
        }),
        components: {},
        computed: {},
        props: ['id', 'collection'],
        methods: {
            getVersions() {
                this.$http.get("/api/versions/" + this.id).then(x => {
                    this.versions = x.data;
                })
            },
            getUser(id) {
                var name = "";
                var user = this.$store.state.users.find(x => x.id == id);
                if (user != null) {
                    name = user.name;
                }
                return name;
            },
            getField(item) {
                var field = this.module.fields.find(x => x.alias == item.name);
                if (field) {
                    return field.name;
                } else {
                    var customFields = this.module.customAreas.flatMap(x => x.customFields);
                    field = customFields.find(x => x.alias == item.name);
                    if (field) {
                        return field.name;
                    }
                }

                if (item.name && item.name.length != 24) {
                    return item.name;
                }

                return null;
            },
            getFieldValue(item) {
                if (item.name == 'alias' && item.value.length == 24) {
                    var field = this.module.fields.find(x => x.alias == item.value);
                    if (field) {
                        return field.name;
                    }
                    var customFields = this.module.customAreas.flatMap(x => x.customFields);
                    field = customFields.find(x => x.alias == item.value);
                    if (field) {
                        return field.name;
                    }
                }
                return item.value;
            },
            changedFields(item) {
                var idx = this.versions.findIndex(x => x.id == item.id);
                var previous = this.versions[(idx + 1)];

                var fields = [];
                var level = 0;
                var result = this.recursiveFields(item.data, previous ? previous.data : null, level);
                fields = fields.concat(result);
                return fields;
                return [];
            },
            recursiveFields(elements, previousElements, level) {
                var fields = [];
                if (!previousElements) {
                    previousElements = [];
                }

                elements.forEach((element, idx) => {
                    if (Array.isArray(element)) {
                        var previous = previousElements[idx];
                        if (JSON.stringify(element) != JSON.stringify(previous)) {
                            var result = this.recursiveFields(element, previous, level + 1);
                            fields = fields.concat(result);
                            fields.push({ level: level + 1, spacer: true });
                        }
                    } else {
                        var previous = previousElements.find(x => x.name == element.name);
                        if (element.name == "specs") {

                        }
                        if (typeof element.value == "object" && element.value != null) {
                            var result = this.recursiveFields(element.value, previous ? previous.value : null, level + 1);
                            if (result.length > 0) {
                                fields.push({ name: element.name, level: level, isNew: previous == null });
                                fields = fields.concat(result);
                            }
                        } else if (typeof element == "object") {
                            if (previous) {
                                if (previous.value != element.value || element.name == 'alias') {
                                    fields.push({ ...element, level: level, isNew: false });
                                }
                            } else {
                                fields.push({ ...element, level: level, isNew: true });
                            }
                        } else {

                        }
                    }

                });

                return fields;
            },

            restoreVersion(id) {
                if (confirm("Er du sikker på du vil gendanne denne version?")) {
                    this.$http.get("/api/versions/restore/" + this.collection + "/" + id).then(x => {
                        if (x.data.success === true) {
                            window.location = window.location;
                        }
                    });
                }
            },
            getMtmTypes() {
                this.$http.get("/api/madetomeasure/product-types").then(x => {
                    this.mtmTypes = x.data;
                })
            },
        },
        created() {
            this.getVersions();
            this.getMtmTypes();
        },
        mounted() { }
    };
</script>