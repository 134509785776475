<template>
	<v-server-table v-if="mounted" ref="dataTable" :apiUrl="url" :name="name" :columns="columns" :options="options" @row-click="rowClick">
		<template v-slot:[item]="props" v-for="item in columns">
			<slot :props="props" :name="item">
				<span :key="item" v-if="fields.find(e => e.name == item).type == 'date'">
					{{props.row[item] && props.row[item] != "" ? $moment(props.row[item]).format("DD-MM-YYYY") : ""}}
				</span>
				<span :key="item" v-else>{{props.row[item]}}</span>
			</slot>
		</template>

		<template v-for="(item, idx) in filters">
			<div :slot="'filter__' + item.name" :key="item.name + '_' + idx">
				<TextFilter :item="item" @refresh="refresh" v-if="item.type == 'text' || item.type == 'hidden'" />
				<TextFilter :item="item" @refresh="refresh" v-if="item.type == 'number'" />
				<DateFilter :item="item" @refresh="refresh" v-if="item.type == 'date'" />
				<UserFilter :item="item" @refresh="refresh" v-if="item.type == 'user'" />
				<UserFilter :item="item" @refresh="refresh" v-if="item.type == 'users-all'" />
				<SelectMultiFilter :item="item" @refresh="refresh" v-if="item.type == 'select'" />
			</div>
		</template>
	</v-server-table>
</template>

<script>
import TextFilter from './tableFilters/text';
import NumberFilter from './tableFilters/number';
import DateFilter from './tableFilters/date';
import UserFilter from './tableFilters/user';
import SelectMultiFilter from './tableFilters/selectMulti';

export default {
	data: () => ({
        firstRequest: true,
        mounted: false,
		options: {
			headings: null,
			sortable: null,
			filterByColumn: false,
			filterable:[],
			requestFunction(data) {
				data.filter = this.$parent.$parent.fields;

				if (this.$parent.$parent.cacheSearch && localStorage) {
					localStorage.setItem(this.$parent.$parent.cacheSearch + "-orderby", JSON.stringify({ column: data.orderBy, ascending: data.ascending == 1, page: data.page }));
				}

				return this.$http.post(this.$parent.$parent.url, data).catch(function (e) {
					this.dispatch('error', e);
				});
			},
			responseAdapter({data}) {
				return data
			},
			orderBy: { ascending: false, column: "CreateDate"  },
			theme: 'bootstrap4',
			sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
			skin: "table table-striped table-sm table-hover",
			columnsClasses: null,
			texts:{
				count:"Viser {from} til {to} af {count} elementer|{count} elementer|1 optegnelse",
				first:'Første',
				last:'Sidste',
				filter:"Filter:",
				filterPlaceholder:"Søg...",
				limit:"Elementer:",
				page:"Side:",
				noResults:"Ingen elementer fundet",
				filterBy:"Filtrér efter {column}",
				loading:'Indlæser...',
				defaultOption:'Vælg {column}',
				columns:'Kolonner'
			},
			perPage: 50,
            initialPage: 1,
			rowClassCallback(row) {
				if (row.selected) {
					return "selected"
				} else {
					return "";
				}
			}
		},
		selected: []
	}),
	props: ["fields", "name", "url", "filter", "skin", "cacheSearch"],
	components: { TextFilter, DateFilter, UserFilter, SelectMultiFilter },
	computed: {
		columns() {
			return this.fields.filter(x => x.visible).map(x => x.name);
		},
		headings() {
			return this.fields.filter(x => x.visible).reduce((map, obj) => (map[obj.name] = obj.label, map), {});
		},
		filters() {
			return this.fields.filter(x => x.filter && (x.visible || x.type == "custom"));
		},
		sortable() {
			return this.fields.filter(x => (x.visible && x.type == "date") || x.sort).map(x => x.name);
		}
	},
	watch: {
		fields() {
			this.init();
			this.refresh();
		},
		filters: {
			handler(val, oldVal) {
				if (this.cacheSearch && localStorage) {
					localStorage.setItem(this.cacheSearch + "-filter", JSON.stringify(this.filters));
				}
			},
			deep: true
		}
	},
	methods: {
		refresh() {
			this.selected = [];

			if (!this.firstRequest) {
				this.$refs.dataTable.setPage(1)
				this.options.initialPage = 1
            }
			
			if (this.$refs.dataTable) {
				this.$refs.dataTable.getData();
			}

			this.firstRequest = false
		},
		init(){
			if (this.cacheSearch && localStorage) {
				var searchOrderBy = localStorage.getItem(this.cacheSearch + "-orderby");
				if (searchOrderBy) {
					try {
						var orderBy = JSON.parse(searchOrderBy);
						this.options.orderBy = orderBy;
						this.options.initialPage = orderBy.page;

					} catch (error) {}
				}
			}

			this.options.filterByColumn = this.filter;
			this.options.headings = this.headings;
			this.options.sortable = this.sortable;
			if (this.skin) {
				this.options.skin = this.skin;
			}
			this.options.columnsClasses = this.fields.filter(x => x.visible).reduce((map, obj) => (map[obj.name] = obj.class, map), {});
		},
		rowClick(e) {
			this.$set(e.row, "selected", !e.row.selected);
			if (e.row.selected) {
				this.selected.push(e.row);
			} else {
				var rowIdx = this.selected.findIndex(x => x.Id == e.row.Id);
				this.selected.splice(rowIdx, 1);
			}
			this.$emit("row-click", this.selected);
		}
	},
	created() {
		this.init();
	},
	mounted() {

        if (this.cacheSearch && localStorage) {
            var searchFilters = localStorage.getItem(this.cacheSearch + "-filter");
            if (searchFilters) {
                try {
                    this.searchFilter = JSON.parse(searchFilters);

                    for (let index = 0; index < this.searchFilter.length; index++) {
                        const element = this.searchFilter[index];
                        var filter = this.filters.find(x => x.name == element.name);
                        var filterIdx = this.filters.findIndex(x => x.name == element.name);
                        if (filter && filter.filter) {
                            if (element.value === undefined) {
                                element.value = null;
                            }
                            this.$set(filter, "value", element.value)
                        }
                    }
                } catch (error) {}
            }
		}

		this.mounted = true

	}
};
</script>
