<template>
	<div class="position-relative has-tool-menu" v-if="entity">
		<div class="tool-menu">
			<Breadcrumb :title="entity.name" />

			<div class="buttons">
				<ButtonAreas :entity="entity" />

				<b-button v-if="!creating" :disabled="loading || entity['63f5991fc9366a02e51c0f94'] == '1'" @click="sendTrustpilot" variant="primary" class="mr-2"><span class="fa fa-envelope"></span> Send Trustpilot</b-button>

				<b-button @click="cancel" variant="secondary" class="mr-2">Fortryd ændringer</b-button>
				<b-button :disabled="loading" @click="submit" variant="success"><span class="fa fa-save"></span> Gem ændringer</b-button>
			</div>
		</div>

		<b-card no-body>
			<b-card-body class="p-1">
				<div class="row">
					<div class="col-12 col-sm-6 input-title">
						<i class="fa fa-file-invoice"></i>
						<span>{{entity.number}}</span>
					</div>
					<div class="col-sm-6 text-right">
						<b-dropdown class="owner-dropdown" variant="white" right>
							<template slot="button-content">
								<span>
									<i class="fa fa-user-circle"></i>
									<span v-if="owner">
										<strong>{{owner.name}}</strong>
										<small>Oprettet af</small>
									</span>
									<span v-else>
										<strong>Ingen ejer</strong>
									</span>
								</span>
							</template>
							<b-dropdown-text v-if="$users.length">
								<FormGroup label="Vælg ejer">
									<b-form-select v-model="entity.owner" :options="$users.filter(x => x.access >= 30)" 
										text-field="name" value-field="id" class="my-2"></b-form-select>
								</FormGroup>
							</b-dropdown-text>
						</b-dropdown>

						<b-dropdown class="m-2 mx-3" variant="outline-dark" right no-caret>
							<template slot="button-content">
								<i class="fa fa-ellipsis-h"></i>
							</template>
							<b-dropdown-item @click="duplicateEntity">Duplikér ordre</b-dropdown-item>
							<b-dropdown-item @click="deleteEntity">Slet ordre</b-dropdown-item>
							<VersionHistory :id="entity.Id" collection="orders" />
						</b-dropdown>
					</div>
				</div>
			</b-card-body>
		</b-card>

		<ModuleLoader v-if="entity" :entity="entity" :bus="bus"></ModuleLoader>

	</div>
</template>

<script>
import mixin from "../mixins/module";
import changeCheck from "../mixins/changeCheck";

export default {
	data: () => ({
		entity: null,
		changes: false,
		loading: false
	}),
	inject: ['$validator'],
	components: {},
	watch: {
		id() {
			this.getEntity();
		},
		entity: { 
			handler(newVal, oldVal) {			
				if (oldVal != null && !this.changes && newVal != null) {
					if (window) {
						window.addEventListener('beforeunload', browserIntercept);
					}
					this.changes = true;
				}
			},
			deep: true
		}
	},
	computed: {
		creating() {
			return this.$route.params.id == "create";
		},
		id() {
			return this.$route.params.id;
		},
		owner() {
			if (!this.$users.length) {
				return null
			}

			if (this.creating && this.entity.owner == null) {
				var currentUser = this.$store.state.user;
				var user = this.$users.find(x => x.email == currentUser);
				if (user) {
					this.entity.owner = user.id;
				}
			}
			return this.$users.find(x => x.id == this.entity.owner);
		}
	},
	methods: {
		getEntity() {
			if (!this.creating) {
				this.$http.get("/api/module/orders/order/" + this.id).then(x => {
					this.entity = x.data;
					this.resetValidation();
					this.resetChanges();
				});
			} else {
				this.entity = {
					number: "Ny ordre",
					contactId: null,
					organizationId: null,
					orderLines: []
				};

				if (this.$route.params.parentModule == "contacts") {
					this.entity.contactId = this.$route.params.parentId;
				}
				else if (this.$route.params.parentModule == "organizations") {
					this.entity.organizationId = this.$route.params.parentId;
				}

				this.resetChanges();
			}
		},
		async submitAction() {		

			if (this.entity.orderLines) {
				this.entity.orderLines.forEach(x => {
					this.$delete(x, "_showDetails");
				});
			}

			if (this.creating) {
				await this.$http.put("/api/module/orders", this.entity).then(x => {
					if (x.data === false) {
						this.showErrorMsg();
					} else {
						this.$router.replace("/orders/" + x.data);
						this.bus.$emit("submit");
						this.showSuccessMsg();
						this.getEntity();
					}

					this.loading = false;
				});
			} else {
				await this.$http.post("/api/module/orders/" +  this.id, this.entity).then(x => {
					if (x.data === false) {
						this.showErrorMsg();
					} else  {
						this.bus.$emit("submit");
						this.showSuccessMsg();
						this.getEntity();	
					}

					this.loading = false;
				});
			}
		},
		cancel() {
			this.bus.$emit("cancel");
			this.getEntity();
		},
		duplicateEntity() {
			if (confirm("Er du sikker på at du vil duplikere " + this.entity.number + "?")) {
				this.resetChanges(true);

				this.$http.post("/api/module/orders/duplicate/" +  this.id).then(x => {
					if (x.data === false) {
						this.showErrorMsg();
					} else  {
						this.bus.$emit("duplicateEntity");
						this.showSuccessMsg();
						this.$router.push("/orders/" + x.data);
					}
				});
			}
		},
		deleteEntity() {
			if (confirm("Er du sikker på at du vil slette " + this.entity.number + "?")) {
				this.resetChanges(true);

				this.$http.delete("/api/module/orders/" +  this.id).then(x => {
					if (x.data === false) {
						this.showErrorMsg();
					} else  {
						this.bus.$emit("deleteEntity");
						this.showSuccessMsg();
						this.$router.replace("/orders");
					}
				});
			}
		},
		submit() {
			this.$validator.validate("module.*").then(async $isValid => {			
				var hasErrors = this.errors.items.filter(x => x.scope == "module").length > 0;
				if ($isValid && !hasErrors) {

					this.loading = true;

					var actions = this.bus.actions.sort((a, b) => {
						return a.sort - b.sort;
					});
					for (let index = 0; index < actions.length; index++) {
						const item = actions[index];

						await item.action();
					}

					this.loading = false;

				} else {
					this.showErrorMsg("Udfyld alle påkrævede felter!");
				}
			});
		},

		sendTrustpilot() {
			this.loading = true

			this.$http.post("/api/module/orders/trustpilot/" + this.id).then(x => {
				this.getEntity()
				this.loading = false
			})			
		}
	},
	created() {
		this.getEntity();

		this.bus.actions.push({ 
			sort: 9000, 
			action: this.submitAction
		});
	},
	mounted() {},
	mixins: [mixin, changeCheck]
};
</script>
