import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { modules } from "../modules";

Vue.use(Vuex)

const INIT_STATE = "INIT_STATE";
const MODULE_RULES = "MODULE_RULES";
const CLEAR_MODULE_RULES = "CLEAR_MODULE_RULES";
const GET_USERS = "GET_USERS";
const GET_ROLES = "GET_ROLES";

// STATE
const state = {
    init: false,
    loggedIn: false,
    lang: null,
    user: null,
    userRole: null,
    menu: null,
    client: null,
    quickActions: null,
    permissions: [],
    editorTypes: [],
    module: {
        name: null,
        access: null,
        tabs: null,
        areas: null,
        fields: null,
        externalTabs: null,
        layouts: null,
        customAreas: null,
        settings: []
    },
    users: [],
    roles: []
}

// ACTIONS
const actions = ({
    [INIT_STATE]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            axios.get("/api/init").then(x => {
                commit(INIT_STATE, x.data)
                resolve()
            }).catch(x => {
                commit(INIT_STATE, null)
                resolve()
            })
        })
    },
    [MODULE_RULES]: ({ commit, dispatch }, model) => {
        var url = [model.module, model.menu].join("/");
        axios.get("/api/module/" + url).then(x => {
            commit(MODULE_RULES, x.data);
        });
    },
    [CLEAR_MODULE_RULES]: ({ commit, dispatch }) => {
        commit(CLEAR_MODULE_RULES);
    },
    [GET_USERS]: ({ commit, dispatch }, route) => {
        var moduleAlias = route.meta.module;
        var menu = route.name;
        return new Promise((resolve, reject) => {
            axios.get("/api/users/all", {
                params: {
                    module: moduleAlias,
                    menu: menu
                }
            }).then(x => {
                commit(GET_USERS, x.data);
                resolve()
            });
        })
    },
    [GET_ROLES]: ({ commit, dispatch }) => {
        axios.get("/api/roles/all").then(x => {
            commit(GET_ROLES, x.data);
        });
    },
})

// MUTATIONS
const mutations = {
    [INIT_STATE]: (state, data) => {
        state.init = true;
        state.loggedIn = data.loggedIn;
        state.lang = data.lang;
        state.user = data.user;
        state.userRole = data.userRole;
        state.menu = data.menu;
        state.client = data.client;
        state.quickActions = data.quickActions;
        state.permissions = data.permissions;
        state.editorTypes = data.editorTypes;
    },
    [MODULE_RULES]: (state, data) => {
        state.module = {
            name: data.name,
            alias: data.alias,
            access: data.module,
            tabs: data.tabs,
            areas: data.areas,
            fields: data.fields,
            externalTabs: data.externalTabs,
            layouts: data.layouts,
            customAreas: data.customAreas,
            settings: data.settings
        };
    },
    [CLEAR_MODULE_RULES]: (state, data) => {
        state.module = {
            name: null,
            access: null,
            tabs: null,
            areas: null,
            fields: null,
            externalTabs: null,
            layouts: null,
            customAreas: null,
            settings: []
        };
    },
    [GET_USERS]: (state, data) => {
        state.users = data;
    },
    [GET_ROLES]: (state, data) => {
        state.roles = data;
    },
}

const store = new Vuex.Store({
    state,
    mutations,
    actions,
    modules
});

export default store;
