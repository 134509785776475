<template>
    <b-form-group :label="model.label" :label-cols="labelCols" :label-cols-sm="labelColsSm" :label-cols-md="labelColsMd" :label-cols-lg="labelColsLg" :label-cols-xl="labelColsXl"
                  :breakpoint="breakpoint" :label-text-align="labelTextAlign" :label-class="labelClass" :class="{ 'has-error': errorMsg }">

        <slot :alias="alias" :scope="scope" :state="state" :label="label"></slot>

        <div v-show="errorMsg" class="invalid-feedback">
            <i :title="errorMsg" class="text-danger fa fa-exclamation-triangle tooltip-icon" v-b-tooltip.hover></i>
        </div>
        <small v-show="description && !errorMsg" class="form-text text-muted">
            <i slot="description" :title="description" class="fa fa-info-circle tooltip-icon" v-b-tooltip.hover></i>
        </small>
    </b-form-group>
</template>

<script>
    export default {
        data: () => ({
            model: {
                label: null
            }
        }),
        inject: ['$validator'],
        components: {},
        computed: {
            validationAlias() {
                return this.name || this.alias
            },
            errorMsg() {
                if (this.validationAlias) {
                    return this.errors.first(this.validationAlias, this.scope)
                } else {
                    return this.error;
                }
            },
            state() {
                var field = null;
                if (this.veeFields["$" + this.scope]) {
                    field = this.veeFields["$" + this.scope][this.validationAlias];
                } else if (this.veeFields[this.validationAlias]) {
                    field = this.veeFields[this.validationAlias];
                } else {
                    return null;
                }
                if (field) {
                    if (field.validated) {
                        if (!field.valid) {
                            return false;
                        }

                    }
                }
                return null;
            }
        },
        watch: {},
        props: [
            "alias",
            "scope",
            "label",
            "labelCols",
            "labelColsSm",
            "labelColsMd",
            "labelColsLg",
            "labelColsXl",
            "description",
            "error",
            "breakpoint",
            "labelTextAlign",
            "labelClass",
            "validated",
            "name"
        ],
        methods: {},
        created() {
            this.model.label = this.label;

            if (this.$parent.field && this.validationAlias) {
                this.model.label = this.$parent.field(this.validationAlias).name;
            }
        },
        mounted() { }
    };
</script>