<template>
    <div class="invoicing position-relative has-tool-menu stock-mgmt">
        <div class="tool-menu">
            <Breadcrumb />

            <div class="buttons">
                <ButtonAreas :entities="selected" />
            </div>
        </div>

        <b-card no-body>
            <b-card-body class="p-2">

                <div class="d-flex" style="column-gap: 25px;">
                    <label>
                        <span class="mb-1 d-block">Store:</span>

                        <b-form-select v-model="form.clientId" class="">
                            <!-- This slot appears above the options from 'options' prop -->
                            <template #first>
                                <b-form-select-option :value="null" disabled>-- Select a store --</b-form-select-option>
                            </template>

                            <!-- These options will appear after the ones from 'options' prop -->
                            <b-form-select-option v-for="item in clients" :key="item.id" :value="item.id">{{item.name}}</b-form-select-option>

                        </b-form-select>
                    </label>

                    <label>
                        <span class="mb-1 d-block">Date from:</span>
                        <b-form-datepicker v-model="form.dateFrom" class="" :start-weekday="1" :no-close-on-select="true"></b-form-datepicker>
                    </label>

                    <label>
                        <span class="mb-1 d-block">Date to:</span>
                        <b-form-datepicker v-model="form.dateTo" class="" :start-weekday="1" :no-close-on-select="true"></b-form-datepicker>
                    </label>

                    <label class="d-flex align-items-end">
                        <b-button @click="exportData" variant="success"><span class="fa fa-file-download"></span> Download</b-button>
                    </label>

                </div>

            </b-card-body>
        </b-card>


    </div>
</template>

<script>
    export default {
        data: () => ({
            selected: [],
            clients: [],
            form: {
                clientId: null,
                dateFrom: null,
                dateTo: null
            }
        }),
        components: {},
        computed: {

        },
        watch: {

        },
        methods: {
            getClients() {
                this.$http.get("/api/invoicing/clients").then(x => {
                    this.clients = x.data
                })
            },

            exportData() {
                this.$http({
                    url: '/api/invoicing/download',
                    data: this.form,
                    method: 'POST',
                    responseType: 'blob', // important
                }).then(x => {
                    this.$fileDownload(x.data, 'Invoicing.xlsx');
                });
            }
        },
        created() {
            this.getClients()
        },
        mounted() { }
    };
</script>

<style lang="scss">

    .invoicing {
        .b-form-btn-label-control.form-control {
            display: flex;
            align-items: stretch;
            height: auto;
            padding: 0;
            background-image: none;
        }

        .b-form-btn-label-control.form-control > .btn {
            display: flex;
            align-items: center;
            font-size: inherit;
            box-shadow: none !important;
            border: 0;
        }

        .b-form-btn-label-control.form-control > .form-control {
            height: auto;
            padding-left: 0.25rem;
            margin: 0;
            border: 0;
            outline: 0;
            background: transparent;
            word-break: break-word;
            font-size: inherit;
            white-space: normal;
            cursor: pointer;
        }

        .b-calendar-nav {
            button.btn {
                color: #2f353a;
                padding: 0.2rem 0.36rem 0.4rem;
            }
        }

        .b-calendar-grid-weekdays {
            margin: 0;
        }

        .b-calendar-grid {
            padding: 0;

            &:focus {
                outline: 0;
                border: 0;
                box-shadow: none;
            }
        }

        .b-calendar-grid-body {
            padding: 0 5px;

            .row .btn {
                width: 32px;
                height: 32px;
                font-size: 14px;
                line-height: 1;
                margin: 3px auto;
                padding: 9px 0;
            }
        }
    }

</style>