<template>
    <div class="position-relative has-tool-menu">
        <div class="tool-menu">
            <Breadcrumb />

            <div class="buttons">
                <ButtonAreas :entities="selected" />

                <b-button @click="exportData" variant="success"><span class="fa fa-file-download"></span> Export</b-button>
            </div>
        </div>

        <b-card no-body>
            <div slot="header">
                <i class="fa fa-building"></i> Ordrer
                <div class="card-header-actions">
                    <!-- <b-link href="#" class="card-header-action btn-setting">
                        <i class="fa fa-cog"></i>
                    </b-link> -->
                </div>
            </div>
            <DataTable ref="dataTable" v-if="fields" url="/api/module/allorders" :fields="fields" name="orders" :filter="true" @row-click="rowClick" cache-search="all-orders">
                <template slot="number" slot-scope="{props}">
                    <b-link append :to="props.row.Id">
                        {{props.row.number}}
                    </b-link>
                </template>
                <template slot="5de4d0649663b9475842a5a5" slot-scope="{props}">
                    <span v-secret="props.row.ClientId">
                        {{props.row['5de4d0649663b9475842a5a5']}}
                    </span>
                </template>
                <template slot="owner" slot-scope="{props}">
                    <span>
                        {{user(props.row.owner).name}}
                    </span>
                </template>

                <template slot="organizationId" slot-scope="{props}">
                    {{props.row.event}}
                </template>

                <template slot="CreateDate" slot-scope="{props}">
                    {{$moment(props.row.CreateDate).format("DD-MM-YYYY HH:mm")}}
                </template>
                <template slot="edit" slot-scope="{props}">
                    <b-button append :to="props.row.Id" variant="primary"><i class="fa fa-eye"></i> Se mere</b-button>
                </template>
            </DataTable>
        </b-card>
    </div>
</template>

<script>

    export default {
        data: () => ({
            fields: null,
            selected: [],
            users: []
        }),
        components: {},
        computed: {},
        methods: {
            rowClick(e) {
                this.selected = e;
            },
            user(id) {
                var usr = this.users.find(x => x.id == id);
                if (usr) {
                    return usr;
                }
                return {};
            },

            exportData() {
                var data = {
                    page: 1,
                    limit: 0,
                    filter: this.fields,
                }

                this.$http({
                    url: '/api/module/allorders/export',
                    data: data,
                    method: 'POST',
                    responseType: 'blob', // important
                }).then(x => {
                    this.$fileDownload(x.data, 'AllOrders.xlsx');
                });
            }
        },
        created() {
            this.$http.get("/api/allorders/all-users").then(x => {
                this.users = x.data;
            })

            this.$http.get("/api/allorders/fields").then(x => {
                this.fields = x.data;

                this.fields.forEach(x => {
                    if (x.type == "user") {
                        x.type = "users-all"
                    }
                })

                this.fields.splice(3, 0, {
                    name: "organizationId",
                    visible: true,
                    filter: false,
                    label: "Event",
                });

                this.fields.splice(4, 0, {
                    name: "ClientId",
                    visible: false,
                    filter: false,
                    label: "Franchise",
                    type: "custom"
                });

                this.fields.push({
                    name: "edit",
                    visible: true,
                    label: "",
                    class: "text-right",
                });
            });


        },
        mounted() { }
    };
</script>