<template>
	<FormGroup :label="f.name || label" :alias="f.alias || alias" :name="name" 
		v-slot:default="props" :scope="scope" 
		v-if="(f.access || access) > 0">
		<multiselect :options="f.values || values" :multiple="false"
			v-model="entity[props.alias]" :label="selectLabel" 
			:disabled="(f.access || access) < 30"
			:allow-empty="!validate" :name="name || props.alias" 
			autocomplete="off" :show-labels="false" :searchable="searchable"
			@input="change"></multiselect>

		<input type="hidden" v-if="validate" v-model="entity[props.alias]" v-validate="validate ? 'required' : null" 
			:name="name || props.alias" :data-vv-as="f.name || label" :data-vv-scope="scope" />
	</FormGroup>
</template>

<script>
export default {
	data: () => ({
		f: {}
	}),
	inject: ['$validator'],
	components: {},
	computed: {},
	watch: {
		"item.values"() {
			this.f.values = this.item.values
		},
	},
	props: {
		item: Object,
		entity: Object,
		label: String,
		scope: String,
		alias: String,
		validate: Boolean,
		values: Array,
		selectLabel: String,
		access: {
			type: Number,
			default: 60
		},
		searchable: {
			type: Boolean,
			default: false
		},
		name: String
	},
	methods: {
		change(e) {
			this.$emit("change", e)
        }
	},
	created() {
		if (this.item) {
			this.f = this.item;
		}
	},
	mounted() {
	}
};
</script>
