<template>
    <div class="position-relative has-tool-menu">
        <div class="tool-menu">
            <Breadcrumb />

            <div class="buttons">
                <ButtonAreas :entities="selected" />

                <b-button @click="exportData" variant="success"><span class="fa fa-file-download"></span> Export</b-button>
            </div>
        </div>

        <b-card no-body>
            <div slot="header">
                <i class="fa fa-user-tie"></i> Production
                <div class="card-header-actions">
                    <!-- <b-link href="#" class="card-header-action btn-setting">
                        <i class="fa fa-cog"></i>
                    </b-link> -->

                    <div class="">
                        <b-form-checkbox v-model="hideUnsentItems.value"
                                         :value="true"
                                         :unchecked-value="false">
                            Hide sent products
                        </b-form-checkbox>
                    </div>
                </div>
            </div>
            <DataTable ref="dataTable" v-if="fields" url="/api/module/suitsyou/production" :fields="fields" name="orders" :filter="true" @row-click="rowClick" cache-search="production">
                <template slot="number" slot-scope="{props}">
                    <b-link append :to="props.row.Id">
                        {{props.row.number}}
                    </b-link>
                </template>

                <template slot="orderLines" slot-scope="{props}">
                    <ul class="comma-list">
                        <template v-for="(itm, i) in props.row.orderLines">
                            <li v-if="!itm.shipped || (!hideUnsentItems.value && itm.shipped)" :key="'prod-' + i" :class="{ 'text-danger': !itm.shipped, 'text-success': itm.shipped, 'font-weight-bold': itm.shipped }">
                                {{itm.product}}
                            </li>
                        </template>
                    </ul>
                </template>

                <template slot="61892fbb0b7efb51f8f086f2" slot-scope="{props}">
                    {{getCompanies(props.row)}}
                </template>

                <template slot="5d9635b3be27b755e8c203e2" slot-scope="{props}">
                    {{$moment(props.row['5d9635b3be27b755e8c203e2']).format("DD-MM-YYYY")}}
                </template>

                <template slot="CreateDate" slot-scope="{props}">
                    {{$moment(props.row.CreateDate).format("DD-MM-YYYY HH:mm")}}
                </template>
                <template slot="edit" slot-scope="{props}">
                    <b-button append :to="props.row.Id" variant="primary"><i class="fa fa-eye"></i> Se mere</b-button>
                </template>
            </DataTable>
        </b-card>
    </div>
</template>

<script>
    export default {
        data: () => ({
            fields: [
                {
                    label: "Order",
                    name: "number",
                    filter: true,
                    type: "text",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {
                    label: "Quantity",
                    name: "orderLines",
                    filter: false,
                    visible: true
                },
                {
                    label: "Status",
                    name: "5df776fb665eb71e687bf338",
                    filter: true,
                    type: "select",
                    visible: true,
                    values: [
                        "New order",
                        "Production started",
                        "Partly sent",
                        "Sent to Denmark",
                        "Cancelled",
                        "Production defect"
                    ]
                },
                {
                    label: "Usage date",
                    name: "5d8a04aefa22d02044989338",
                    filter: true,
                    type: "date",
                    visible: true
                },
                {
                    label: "Shipping deadline",
                    name: "5d8a04bafa22d02044989339",
                    filter: true,
                    type: "date",
                    visible: true
                },
                {
                    label: "Company",
                    name: "61892fbb0b7efb51f8f086f2",
                    filter: true,
                    type: "select",
                    visible: true,
                    values: [
                        "W",
                        "D",
                        "P",
                        "V",
                        "B"
                    ],
                    sort: true
                },
                {
                    label: "Producer",
                    name: "5d8a057dfa22d0204498933d",
                    filter: true,
                    type: "text",
                    visible: true,
                    sort: true
                },
                {
                    label: "Created",
                    name: "CreateDate",
                    filter: true,
                    type: "date",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {
                    name: "edit",
                    visible: true,
                    label: "",
                    class: "text-right",
                },
                {
                    name: "hideUnsentItems",
                    visible: false,
                    filter: true,
                    type: "custom",
                    value: false
                }
            ],
            selected: []
        }),
        components: {},
        watch: {
            "hideUnsentItems.value"(val) {
                var field = this.fields.find(x => x.name == "hideUnsentItems")
                field.value = val
                var idx = this.fields.findIndex(x => x.name == field.name)

                this.$set(this.fields, idx, field)
            }
        },
        computed: {
            hideUnsentItems() {
                return this.fields.find(x => x.name == "hideUnsentItems")
            }
        },
        methods: {
            rowClick(e) {
                if (e.length > 0) {
                    this.$router.push({ path: "/production/" + e[0].Id });
                }
            },
            getCompanies(row) {
                var companies = row.orderLines
                    .filter(x => !x.shipped || (!this.hideUnsentItems.value && x.shipped))
                    .map(x => x.company).filter(x => x != null && x != "")

                if (companies.length > 0) {
                    return companies.join(", ")
                }

                return row['61892fbb0b7efb51f8f086f2']
            },
            exportData() {

                var sort = this.$refs.dataTable.options.orderBy

                var data = {
                    page: 1,
                    limit: 0,
                    filter: this.fields,
                    orderBy: sort.column,
                    ascending: sort.ascending ? 1 : 0
                }

                this.$http({
                    url: '/api/module/suitsyou/productions/export',
                    data: data,
                    method: 'POST',
                    responseType: 'blob', // important
                }).then(x => {
                    this.$fileDownload(x.data, 'Production.xlsx');
                });
            }
        },
        created() {
        },
        mounted() { }
    };
</script>
