<template>
    <div>
        <b-card no-body class="mb-1" v-for="(order, okey) in orders" :key="okey">
            <b-card-header header-tag="header" class="">
                <div class="row">
                    <div class="col-sm-5">
                        <router-link :to="'/orders/' + order._id" target="_blank">
                            {{order.number}}
                        </router-link>

                    </div>
                    <div class="col-sm-7 text-right">
                        {{$moment(order.CreateDate).format("DD-MM-YYYY HH:mm")}}
                    </div>
                </div>
            </b-card-header>
            <b-card-body class="p-0">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>Stof</th>
                            <th>Reserveret</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, key) in order.orderLines">
                            <tr :key="key" v-if="hasStock(item)">
                                <td>
                                    {{item.product}}
                                </td>
                                <td>
                                    {{stock.name}}
                                </td>
                                <td>
                                    {{getStock(item)}}
                                </td>
                            </tr>
                        </template>

                    </tbody>
                </table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
    export default {
        data: () => ({
            orders: []
        }),
        components: {},
        computed: {},
        props: ['stock'],
        methods: {

            getReservedOrders() {
                this.$http.get("/api/stock/reserved?id=" + this.stock.id).then(x => {
                    this.orders = x.data
                })
            },
            hasStock(item) {
                if (!item.stock) {
                    return false
                }
                if (!item.stock[this.stock.type]) {
                    return false
                }
                if (!item.stock[this.stock.type][this.stock.id]) {
                    return false
                }
                if (item.company) {
                    return false
                }

                return true
            },
            getStock(item) {
                return item.stock[this.stock.type][this.stock.id]
            }

        },
        created() {
            this.getReservedOrders();
        },
        mounted() { }
    };
</script>