<template>
    <FormGroup :label="f.name || label" :alias="f.alias || alias" :name="name"
               v-slot:default="props" :scope="scope"
               v-if="(f.access || access) > 0">
        <flat-pickr v-if="(f.access || access) >= 30"
                    v-model="entity[f.alias || alias]"
                    :config="config"
                    placeholder="Vælg dato"
                    :name="name || props.alias"
                    v-validate="validateRule"
                    :data-vv-scope="props.scope"
                    :data-vv-as="f.name || label"
                    class="form-control">
        </flat-pickr>
        <b-input-group v-else>
            <b-form-input type="text" placeholder="Vælg dato"
                          :value="entity[(f.alias || alias)]"
                          :disabled="(f.access || access) < 30"></b-form-input>
        </b-input-group>
    </FormGroup>
</template>

<script>
    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    import 'flatpickr/dist/themes/airbnb.css';
    import { Danish } from 'flatpickr/dist/l10n/da.js';

    export default {
        data: (self) => ({
            f: {},
            config: {
                wrap: false,
                altInput: true,
                dateFormat: "YYYY-MM-DD",
                altFormat: "DD-MM-YYYY",
                locale: Danish,
                disableMobile: true,
                mode: "single",
                parseDate: (datestr, format) => {
                    var d = self.$moment(datestr).utc().toDate()
                    return d
                },
                formatDate: (date, format, locale) => {
                    var d = self.$moment(date).format(format)
                    return d
                },
                //weekNumbers: true
            },
        }),
        inject: ['$validator'],
        components: { flatPickr },
        computed: {
            validateRule() {
                if (this.validate === true) {
                    return "required";
                }

                if (this.validate === false || this.validate == null) {
                    return null;
                }

                if (this.validate) {
                    return this.validate;
                }
            }
        },
        watch: {
            // entityValue(val) {
            // 	this.$emit('input', val);
            // }
        },
        props: {
            item: Object,
            entity: Object,
            label: String,
            scope: String,
            alias: String,
            name: String,
            validate: [Boolean, String, Object],
            access: {
                type: Number,
                default: 60
            },
            // value: String
        },
        methods: {

        },
        created() {
            if (this.item) {
                this.f = this.item;

                // this.$emit('input', this.entityValue);
            }
        },
        mounted() { }
    };
</script>