<template>
	<b-input-group class="table-filter select-multi">
		<multiselect :options="item.values" v-model="item.value" :multiple="true" :show-labels="false" @input="refresh"
			:allow-empty="true" :close-on-select="false" :name="item.alias" autocomplete="off"></multiselect>
	</b-input-group>
</template>

<script>
export default {
	data: () => ({
	}),
	inject: ['$validator'],
	components: {},
	computed: {},
	watch: {},
	props: {
        item: Object
    },
	methods: {
		refresh() {
	        this.$nextTick(() => {
                this.$emit("refresh");
            })
		},
		clear() {
			this.item.value = null;
			this.refresh();
		}
	},
	created() {},
	mounted() {}
};
</script>