<template>
    <b-card-body v-if="result">
        
        <table class="table table-md mb-3">
            <thead>
                <tr>
                    <th>Orders</th>
                    <th>Shipped on time</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{result.orders}}</td>
                    <td>{{result.data}}%</td>
                </tr>
            </tbody>
        </table>

    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";

export default {
    data: () => ({
        result: null,
    }),
    components: {  },
    watch: {
        entity: {
            deep: true,
            handler() {
                this.getResult();
            }
        }
    },
    computed: {},
    methods: {
        getResult() {
            this.$http.post("/api/module/dashboard/deliveryperformance", {
                dates: this.entity.dates,
                user: this.entity.user,
                franchise: this.entity.franchise
            }).then(x => {
                this.result = x.data;
            });
        },
    },
    created() {
        this.getResult();
    },
    mounted() {

    },
    mixins: [mixin]
};
</script>

<style lang="scss">


</style>