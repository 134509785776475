<template>
    <b-card-body class="pt-0">
        <div class="row">
            <div class="col-12">
                <b-form-group label="Status" label-cols-sm="2" label-cols-md="2" class="mt-2" label-class="px-1 py-2 font-weight-bold">
                    <b-form-radio-group v-model="status" size="md" buttons button-variant="secondary" internal="Status">
                        <b-radio v-for="item in statuses" :key="item.value" :value="item.value" :button-variant="item.buttonVariant" @change="updateOrder">
                            {{item.value}}
                        </b-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>

            <!--<div class="col-12">
                <b-form-group label="Company" label-cols-sm="2" label-cols-md="2" class="mt-2" label-class="px-1 py-2 font-weight-bold">
                    <b-form-checkbox-group v-model="selectedCompanies"
                                           :options="companies"
                                           buttons
                                           button-variant="outline-dark"
                                           size="lg" @change="updateOrder">
                    </b-form-checkbox-group>
                </b-form-group>
            </div>-->

            <div class="col-12" v-if="entity['5d8a0355fa22d02044989332'] == 'Earlier production defect (no billing)'">
                <b-form-group label="Order type" label-cols="6" label-cols-sm="3" class="m-0" label-class="px-1 py-2 font-weight-bold">
                    <div class="py-2 btn btn-danger">
                        {{entity['5d8a0355fa22d02044989332']}}
                    </div>
                </b-form-group>
            </div>

            <div class="col-12">
                <b-form-group label="Shipping deadline" label-cols="6" label-cols-sm="3" class="m-0" label-class="px-1 py-2 font-weight-bold">
                    <div class="py-2">
                        {{$moment(entity['5d8a04bafa22d02044989339']).format("DD-MM-YYYY")}}
                    </div>
                </b-form-group>
            </div>

            <div class="col-12">
                <b-form-group label="Gender" label-cols="6" label-cols-sm="3" class="m-0" label-class="px-1 py-2 font-weight-bold">
                    <div class="py-2">
                        {{entity['5d7b8ab52dce1817fc348cfa']}}
                    </div>
                </b-form-group>
            </div>
        </div>
    </b-card-body>
</template>

<script>
    import mixin from "../../mixins/area";
    import Editors from "../../mixins/editors";

    export default {
        data: () => ({
            stacked: false,
            statuses: [
                {
                    value: "Production started",
                    buttonVariant: "outline-warning"
                },
                {
                    value: "Partly sent",
                    buttonVariant: "outline-dark"
                },
                {
                    value: "Sent to Denmark",
                    buttonVariant: "outline-success"
                },
                {
                    value: "Cancelled",
                    buttonVariant: "outline-danger"
                },
                {
                    value: "Production defect",
                    buttonVariant: "outline-danger"
                },
                // {
                //     value: "Deadline exceeded",
                //     buttonVariant: "outline-danger"
                // }
            ],
            companies: ["W", "D", "P", "V", "B"],
            status: null,
            company: null,
        }),
        components: { ...Editors },
        computed: {
            selectedCompanies: {
                get() {
                    if (this.company == null || this.company == "") {
                        return []
                    }
                    return this.company.split(',')
                },
                set(val) {
                    this.company = val.join()
                }
            }
        },
        methods: {
            updateOrder() {
                this.$http.post("/api/module/suitsyou/production/" + this.entity.Id, { status: this.status, company: this.company }).then(x => {

                    this.bus.$emit("reload");
                });
            }
        },
        created() {
            this.status = this.entity["5df776fb665eb71e687bf338"];
            this.company = this.entity["61892fbb0b7efb51f8f086f2"]
        },
        mounted() { },
        mixins: [mixin]
    };
</script>
