<template>
	<b-input-group>
		<b-form-input type="number" :placeholder="item.label" @change="refresh" v-model="item.value"></b-form-input>
		<b-input-group-append>
			<b-button variant="secondary" @click="clear"><i class="fa fa-times"></i></b-button>
		</b-input-group-append>
	</b-input-group>
</template>

<script>
export default {
	data: () => ({}),
	components: {},
	computed: {},
	props: ["item"],
	methods: {
		refresh() {
	        this.$nextTick(() => {
                this.$emit("refresh");
            })
		},
		clear() {
			this.item.value = null;
			this.refresh();
		}
	},
	created() {},
	mounted() {}
};
</script>