<template>
    <div class="app">
        <AppHeader fixed>
            <SidebarToggler class="d-lg-none" display="md" mobile stroke="#b2ccdb" />
            <b-link class="navbar-brand" to="/">
                <img class="navbar-brand-full" src="/img/brand/logo.svg" height="30" alt="Optify">
                <img class="navbar-brand-minimized" src="/img/brand/sygnet.svg" width="30" height="30" alt="Optify">
            </b-link>
            <SidebarToggler class="d-md-down-none" display="lg" />

            <ul class="navbar-nav d-md-down-none">
                <component :is="'qa-' + item.alias" v-for="item in quickActions" :key="item.alias"></component>
            </ul>

            <b-navbar-nav class="ml-auto">
                <DefaultHeaderDropdownAccnt class="mr-2" />
            </b-navbar-nav>
        </AppHeader>
        <div class="app-body">
            <AppSidebar fixed>
                <SidebarHeader />
                <SidebarForm />
                <SidebarNav :navItems="nav"></SidebarNav>
                <SidebarFooter />
                <SidebarMinimizer />
            </AppSidebar>
            <main class="main">
                <div class="container-fluid">
                    <router-view></router-view>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
    import {
        Header as AppHeader,
        SidebarToggler,
        Sidebar as AppSidebar,
        SidebarFooter,
        SidebarForm,
        SidebarHeader,
        SidebarMinimizer,
        SidebarNav,
        Aside as AppAside,
        AsideToggler
    } from "@coreui/vue";
    import DefaultHeaderDropdownNotif from "./DefaultHeaderDropdownNotif.vue";
    import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt.vue";
    import DefaultHeaderDropdownMssgs from "./DefaultHeaderDropdownMssgs.vue";
    import DefaultHeaderDropdownTasks from "./DefaultHeaderDropdownTasks.vue";

    export default {
        name: "DefaultContainer",
        components: {
            AsideToggler,
            AppHeader,
            AppSidebar,
            AppAside,
            DefaultHeaderDropdownMssgs,
            DefaultHeaderDropdownNotif,
            DefaultHeaderDropdownTasks,
            DefaultHeaderDropdownAccnt,
            SidebarForm,
            SidebarFooter,
            SidebarToggler,
            SidebarHeader,
            SidebarNav,
            SidebarMinimizer
        },
        data: () => ({

        }),
        computed: {
            nav() {
                return this.$store.state.menu || [];
            },
            quickActions() {
                return this.$store.state.quickActions || [];
            }
        },
        created() {
            if (document.documentElement.clientWidth <= 1280) {
                document.body.classList.add("sidebar-minimized");
                document.body.classList.add("brand-minimized");
            }
        },
        mounted() {

        }
    };
</script>
