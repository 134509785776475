<template>
    <b-card-body>
        <div class="row">
            <div class="col-md-6">
                <EditorNumber :item="field('5d7bb3fe2dce1817fc348f7e')" :entity="entity" scope="module" :validate="false" />
            </div>
            <div class="col-md-6">
                <EditorNumber :item="field('5d7bb4062dce1817fc348f7f')" :entity="entity" scope="module" :validate="false" />
            </div>

            <div class="measurement col-12 mt-2" :key="'type-' + key" v-for="(type, key) in productTypes">
                <b-button @click="getProductType(type.id)" class="text-left" block v-b-toggle="'type-' + key" variant="light"><strong>{{type.name}}</strong></b-button>
                <b-collapse :id="'type-' + key" accordion="types" class="">

                    <div class="row px-1 py-2" v-if="productType">
                        <div class="col-12">
                            <FormGroup label="Size" label-cols-md="3">
                                <b-form-select :options="type.sizes" class="" @change="setSize($event, type.sizes)" :value="getMeasurement().sizeId" text-field="name" value-field="sizeId">
                                    <template slot="first">
                                        <option :value="null">Choose size</option>
                                    </template>
                                </b-form-select>
                            </FormGroup>
                        </div>
                        <div class="col-12 col-sm-6" v-for="(group, tkey) in sizeFields" :key="tkey">
                            <table class="table table-sm font-xs">
                                <thead>
                                    <tr>
                                        <th>Measurement</th>
                                        <th>Standard</th>
                                        <th>Change</th>
                                        <th>Note/Guide</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in group" :key="item.productType">
                                        <td class="align-middle p-1 text-nowrap">
                                            {{item.name}}
                                        </td>
                                        <td class="align-middle p-1" width="30">
                                            <b-form-input readonly type="text" class="font-xs p-1" placeholder="Standard" :value="item.value"></b-form-input>
                                        </td>
                                        <td class="align-middle p-1">
                                            <b-input-group class="p-0 flex-nowrap">
                                                <b-form-input type="text" placeholder="Change" class="font-xs p-1" :value="getField(item).addition"
                                                              @input="setField(item, $event)" style="width: 50px;"></b-form-input>
                                                <b-input-group-append>
                                                    <button @click="addChange(item, 0.5)" class="btn btn-sm btn-default py-0 px-2 my-0">
                                                        <i class="fa fa-plus" />
                                                    </button>
                                                    <button @click="addChange(item, -0.5)" class="ml-1 btn btn-sm btn-default py-0 px-2 my-0">
                                                        <i class="fa fa-minus" />
                                                    </button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </td>
                                        <td class="align-middle p-1">
                                            <span class="">{{item.note}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <!--<b-input-group class="mt-1">
                                <span class="px-2 p-1 mr-2">{{item.name}}</span>
                                <b-input-group-append>
                                    <b-form-input readonly type="text" placeholder="Standard" :value="item.value"></b-form-input>

                                    <b-form-input type="text" placeholder="Change" :value="getField(item).addition"
                                                  @input="setField(item, $event)"></b-form-input>

                                    <span class="px-2 p-1 mr-2">{{item.note}}</span>
                                </b-input-group-append>
                            </b-input-group>-->
                        </div>
                    </div>

                </b-collapse>
            </div>
        </div>
    </b-card-body>
</template>

<script>
    import mixin from "../../mixins/area";
    import Editors from "../../mixins/editors";

    export default {
        data: () => ({
            productTypes: [],
            mtmFields: [],
            productType: null
        }),
        components: { ...Editors },
        computed: {
            sizeFields() {
                var fields = [];
                if (this.productType) {
                    var item = this.entity.madeToMeasure.find(x => x.productType == this.productType.id);

                    if (item) {
                        var size = this.productType.sizes.find(x => x.sizeId == item.sizeId)
                        if (size && size.fields) {
                            size.fields.forEach(f => {
                                var field = this.mtmFields.find(x => x.alias == f.fieldAlias)
                                fields.push({
                                    name: f.name || field.name,
                                    alias: f.fieldAlias,
                                    value: f.value,
                                    addition: null,
                                    total: null,
                                    note: f.note
                                })
                            })
                        }
                    }
                }

                var firstGroup = fields.slice(0, fields.length / 2);
                var secondGroup = fields.slice(fields.length / 2);

                return [firstGroup, secondGroup];
            }
        },
        methods: {
            getFields() {
                this.$http.get("/api/madetomeasure/fields").then(x => {
                    this.mtmFields = x.data;
                });
            },
            getProductTypes() {
                this.$http.get("/api/madetomeasure/product-types").then(x => {
                    this.productTypes = x.data;
                });
            },
            getProductType(id) {
                this.$http.get("/api/madetomeasure/product-types/" + id).then(x => {
                    this.productType = x.data;
                });
            },

            getMeasurement() {
                var item = this.entity.madeToMeasure.find(x => x.productType == this.productType.id)
                if (item) {
                    return item
                }
                return {
                    productType: null,
                    sizeId: null,
                    size: null,
                    fields: []
                }
            },
            setSize(id, sizes) {
                var size = sizes.find(x => x.sizeId == id)
                var sizeName = null
                var item = this.entity.madeToMeasure.find(x => x.productType == this.productType.id);

                if (size) {
                    sizeName = size.name
                }

                if (!item) {
                    item = {
                        productType: this.productType.id,
                        sizeId: id,
                        size: sizeName,
                        fields: []
                    }
                    this.entity.madeToMeasure.push(item)
                } else {
                    item.sizeId = id
                    item.size = sizeName
                }
            },
            getField(field) {
                var item = this.entity.madeToMeasure.find(x => x.productType == this.productType.id);

                var itemField = item.fields.find(x => x.alias == field.alias)

                field.value = field.value.replace(',', '.')

                if (itemField) {
                    const regex = /^\d+(\.\d+)?/;
                    if (regex.test(itemField.addition)) {
                        itemField.addition = itemField.addition.replace('.', ',')
                        itemField.addition = itemField.addition.replace(/,(?=.*,)/g, '')
                    }

                    var val = this.$numeral(parseFloat(field.value)).format("0.[00]")

                    itemField.value = this.$numeral(parseFloat(field.value)).format("0.[00]")
                    itemField.total = this.$numeral(val).value() + this.$numeral(itemField.addition).value()
                    itemField.addition = this.$numeral(itemField.addition).format("0.[00]")
                } else {
                    var val = this.$numeral(parseFloat(field.value)).format("0.[00]")

                    itemField = {
                        alias: field.alias,
                        value: val,
                        addition: null,
                        total: parseFloat(field.value),
                    }
                    item.fields.push(itemField)
                }

                return itemField
            },
            setField(field, val) {
                val = val.replace(".", ",")
                val = val.replace(/,(?=.*,)/g, '');

                field.value = field.value.replace(',', '.')

                var item = this.entity.madeToMeasure.find(x => x.productType == this.productType.id);

                var itemField = item.fields.find(x => x.alias == field.alias)

                var fieldValue = this.$numeral(parseFloat(field.value)).format("0.[00]")

                itemField.value = fieldValue
                itemField.addition = val
                itemField.total = this.$numeral(fieldValue).value() + this.$numeral(itemField.addition).value()
            },
            addChange(field, inc) {
                var item = this.entity.madeToMeasure.find(x => x.productType == this.productType.id);

                var itemField = item.fields.find(x => x.alias == field.alias)

                var val = "0"
                if (itemField && itemField.addition) {
                    val = itemField.addition
                }

                field.value = field.value.replace(',', '.')

                var fieldValue = this.$numeral(parseFloat(field.value)).format("0.[00]")

                itemField.value = fieldValue
                itemField.addition = this.$numeral(val).add(inc).format("0.[0]")
                itemField.total = this.$numeral(fieldValue).value() + this.$numeral(itemField.addition).value()
            },
        },
        created() {
            this.getFields();
            this.getProductTypes();

            if (!this.entity.madeToMeasure) {
                this.$set(this.entity, "madeToMeasure", []);
            }
        },
        mounted() { },
        mixins: [mixin]
    };
</script>