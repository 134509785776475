<template>
	<input type="hidden" 
		:name="name || f.alias || alias" 
		v-model="entity[(f.alias || alias)]" 
		v-if="(f.access || access) > 0"
		:disabled="(f.access || access) < 30" />
</template>

<script>
export default {
	data: () => ({
		f: {}
	}),
	components: {},
	computed: {
	},
	watch: {},
	props: {
		item: Object,
		entity: Object,
		label: String,
		scope: String,
		alias: String,
		name: String,
		validate: Boolean,
		access: {
			type: Number,
			default: 60
		}
	},
	methods: {},
	created() {
		if (this.item) {
			this.f = this.item;
		}
	},
	mounted() {}
};
</script>