import "./style.scss";

import Vue from 'vue'
const routes = [];

const RouterView = { render (c) { return c('router-view') } };

import Stock from "./stock";
import StockLog from "./stockLog";

routes.push({ path: "stock", component: RouterView, meta: { label: "Lagerstyring" }, children: [
    { path: "", component: Stock, name: "stock", meta: { module: "stock" } },
    { path: "stockLog", component: StockLog, name: "stockLog", meta: { module: "stock" } },
] });

export { routes };
