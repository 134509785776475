<template>
    <div class="position-relative has-tool-menu">
        <div class="tool-menu">
            <Breadcrumb />

            <div class="buttons">
                <ButtonAreas :entities="selected" />

                <b-button @click="exportData" variant="primary" class="mr-2"><span class="fa fa-file-download"></span> Export</b-button>

                <b-button v-if="module.access >= 30" variant="success" @click="newOrder"><span class="fa fa-plus"></span> Opret ordre</b-button>
            </div>
        </div>



        <b-card no-body>
            <div slot="header">
                <i class="fa fa-building"></i> Ordrer
                <div class="card-header-actions">
                    <!-- <b-link href="#" class="card-header-action btn-setting">
                        <i class="fa fa-cog"></i>
                    </b-link> -->
                </div>
            </div>
            <DataTable ref="dataTable" v-if="fields" url="/api/module/orders" :fields="fields" name="orders" :filter="true" @row-click="rowClick" cache-search="orders">
                <template slot="number" slot-scope="{props}">
                    <b-link append :to="props.row.Id">
                        {{props.row.number}}
                    </b-link>
                </template>
                <template slot="owner" slot-scope="{props}">
                    {{$user(props.row.owner).name}}
                </template>

                <template slot="organizationId" slot-scope="{props}">
                    {{props.row.event}}
                </template>

                <template slot="CreateDate" slot-scope="{props}">
                    {{$moment(props.row.CreateDate).format("DD-MM-YYYY HH:mm")}}
                </template>
                <template slot="edit" slot-scope="{props}">
                    <b-button append :to="props.row.Id" variant="primary"><i class="fa fa-eye"></i> Se mere</b-button>
                </template>
            </DataTable>
        </b-card>
    </div>
</template>

<script>
    import newOrder from "./modals/_newOrder.vue";

    export default {
        data: () => ({
            fields: null,
            selected: []
        }),
        components: {},
        computed: {},
        methods: {
            newOrder() {
                this.$router.push({
                    name: 'order',
                    params: { id: 'create' }
                });
            },
            rowClick(e) {
                this.selected = e;
            },

            exportData() {
                var data = {
                    page: 1,
                    limit: 0,
                    filter: this.fields,
                }

                this.$http({
                    url: '/api/module/orders/export',
                    data: data,
                    method: 'POST',
                    responseType: 'blob', // important
                }).then(x => {
                    this.$fileDownload(x.data, 'Orders.xlsx');
                });
            }
        },
        created() {
            this.$http.get("/api/module/orders/fields").then(x => {
                this.fields = x.data;

                this.fields.splice(3, 0, {
                    name: "organizationId",
                    visible: true,
                    filter: false,
                    label: "Event",
                });

                this.fields.splice(7, 1)

                this.fields.push({
                    name: "edit",
                    visible: true,
                    label: "",
                    class: "text-right",
                });
            });
        },
        mounted() { }
    };
</script>