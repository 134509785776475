import Vue from 'vue'

const routes = [];
const modules = {};

import * as dashboard from "./views/dashboard/module";

import * as organizations from "./views/organizations/module";
routes.push(...organizations.routes);
modules.organizations = organizations.store;

import * as contacts from "./views/contacts/module";
routes.push(...contacts.routes);
modules.contacts = contacts.store;

import * as products from "./views/products/module";
routes.push(...products.routes);
modules.products = products.store;

import * as orders from "./views/orders/module";
routes.push(...orders.routes);
modules.orders = orders.store;

import * as notes from "./views/notes/module";
routes.push(...notes.routes);
modules.notes = notes.store;

import * as files from "./views/files/module";
routes.push(...files.routes);
modules.files = files.store;

import * as users from "./views/users/module";
routes.push(...users.routes);
modules.users = users.store;

import * as suitsyou from "./views/suitsyou/module";
routes.push(...suitsyou.routes);
modules.suitsyou = suitsyou.store;

import * as allorders from "./views/allOrders/module";
routes.push(...allorders.routes);

import * as stock from "./views/stock/module";
routes.push(...stock.routes);

import * as invoicing from "./views/invoicing/module";
routes.push(...invoicing.routes);

export { routes, modules };
