<template>
	<b-dropdown-item @click="openVersions">Changelog</b-dropdown-item>
</template>

<script>
import modal from "./versionHistoryModal.vue";

export default {
	data: () => ({}),
	components: {},
	props: ['id', 'collection'],
	computed: {},
	methods: {
		openVersions() {
			this.$dialog.open(modal, { id: this.id, collection: this.collection }, { title: "Changelog", okTitle: "Luk", okOnly: true, size: "md" })
		}
	},
	created() {},
	mounted() {}
};
</script>