<template>
	<FormGroup :label="f.name || label" :alias="f.alias || alias" :name="name"
		v-slot:default="props" :scope="scope" 
		v-if="(f.access || access) > 0">
		<b-form-input type="text" readonly v-model="entity[props.alias]" 
			:state="props.state" :data-vv-scope="props.scope" 
			:disabled="(f.access || access) < 30"
			:name="name || props.alias" :data-vv-as="f.name || label" 
			autocomplete="off"></b-form-input>
	</FormGroup>
</template>

<script>
export default {
	data: () => ({
		f: {}
	}),
	inject: ['$validator'],
	components: {},
	computed: {
	},
	watch: {},
	props: {
		item: Object,
		entity: Object,
		label: String,
		scope: String,
		alias: String,
		name: String,
		validate: Boolean,
		access: {
			type: Number,
			default: 60
		}
	},
	methods: {},
	created() {
		if (this.item) {
			this.f = this.item;
		}
	},
	mounted() {}
};
</script>