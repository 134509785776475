<template>
    <div>
        <table class="table table-sm table-bordered mb-2" v-for="(group, gkey) in log" :key="gkey">
            <thead>
                <tr>
                    <th style="width: 125px">
                        <small style="font-size: 9px;">{{group[0].order}}</small>
                    </th>
                    <th style="width: 260px"></th>
                    <th style="width: 100px"></th>

                    <th colspan="3" style="border-right: 2px solid #a9a9a9">Reserved</th>

                    <th colspan="5" style="width: 165px">Company</th>
                </tr>
                <tr>
                    <th>Date</th>
                    <th>Reason</th>
                    <th>Product</th>

                    <th>Before</th>
                    <th></th>
                    <th style="border-right: 2px solid #a9a9a9">After</th>

                    <th colspan="2">Before</th>
                    <th></th>
                    <th colspan="2">After</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, key) in group" :key="item.id">
                    <td>
                        <router-link target="_blank" :to="'/orders/' + item.order">{{$moment(item.createDate).format('DD-MM-yyyy HH:mm')}}</router-link>
                    </td>
                    <td :style="isPrevious(item)">
                        {{item.reason}}
                    </td>
                    <td>
                        <template v-if="item.product">
                            {{item.orderLine}} {{item.product}}
                        </template>
                    </td>
                    <td :style="isPrevious(item)">
                        <template v-if="item.before">
                            {{item.before.reservedAmount}}
                        </template>
                    </td>
                    <td  style="text-align: right; color: #009613">

                        <template v-if="item.after && !item.company">
                            {{$numeral(item.before.reservedAmount).subtract($numeral(item.after.reservedAmount).value()).value() * -1}}
                        </template>

                    </td>
                    <td :style="isPrevious(item)" style="border-right: 2px solid #a9a9a9">
                        <template v-if="item.after && !item.company">
                            {{item.after.reservedAmount}}
                        </template>
                    </td>



                    <td>
                        <template v-if="item.before && item.company == '*'">
                            <div v-for="company in item.before.producers">
                                {{company.name}}
                            </div>
                        </template>
                        <template v-else>
                            {{item.company}}
                        </template>
                    </td>
                    <td :style="isPrevious(item)">
                        <template v-if="item.before && item.company == '*'">
                            <div v-for="company in item.before.producers">
                                {{company.stockAmount}}
                            </div>
                        </template>
                        <template v-else-if="item.before && item.company">
                            {{item.before.producers.find(x => x.name == item.company).stockAmount}}
                        </template>
                    </td>
                    <td  style="text-align: right; color: #009613">

                        <template v-if="item.before && item.after && item.company && item.company != '*'">
                            {{$numeral(item.before.producers.find(x => x.name == item.company).stockAmount).subtract($numeral(item.after.producers.find(x => x.name == item.company).stockAmount).value()).value() * -1}}
                        </template>

                    </td>
                    <td>
                        <template v-if="item.after && item.company == '*'">
                            <div v-for="company in item.after.producers">
                                {{company.name}}
                            </div>
                        </template>
                        <template v-else>
                            {{item.company}}
                        </template>
                    </td>
                    <td :style="isPrevious(item)">
                        <template v-if="item.after && item.company == '*'">
                            <div v-for="company in item.after.producers">
                                {{company.stockAmount}}
                            </div>
                        </template>
                        <template v-else-if="item.after && item.company">
                            {{item.after.producers.find(x => x.name == item.company).stockAmount}}
                        </template>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        data: () => ({
            log: []
        }),
        components: {},
        computed: {},
        props: ['id'],
        methods: {

            getLog() {
                this.$http.get("/api/stock/log?id=" + this.id).then(x => {
                    this.log = x.data.log
                })
            },
            isPrevious(item) {
                return item.reason.indexOf('previous') > -1 ? 'color: red;' : ''
            }

        },
        created() {
            this.getLog();
        },
        mounted() { }
    };
</script>