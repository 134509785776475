import "./style.scss";

import Vue from 'vue'
const routes = [];

const RouterView = { render (c) { return c('router-view') } };

import Orders from "./orders";
import Order from "../orders/order.vue";

routes.push({ path: "allorders", component: RouterView, meta: { label: "Ordrer" }, children: [
    { path: "", component: Orders, name: "allOrders", meta: { module: "allOrders" } },
    { path: ":id", component: Order, name: "order", meta: { label: "Ordre", module: "orders" } }
] });

export { routes };
