<template>
	<div class="position-relative has-tool-menu" v-if="entity">
		<div class="tool-menu">
			<Breadcrumb :title="entity.name" />

			<div class="buttons">
				<ButtonAreas :entity="entity" />
				
				<b-button v-if="!creating" @click="deleteEntity" variant="danger"><span class="fa fa-save"></span> Slet bruger</b-button>
				<b-button @click="cancel" variant="secondary">Fortryd ændringer</b-button>
				<b-button @click="submit" variant="success"><span class="fa fa-save"></span> Gem ændringer</b-button>
			</div>
		</div>

		<ModuleLoader v-if="entity" :entity="entity" :bus="bus"></ModuleLoader>

	</div>
</template>

<script>
import mixin from "../mixins/module";

export default {
	data: () => ({
		entity: null
	}),
	components: {},
	watch: {
		id() {
			this.getEntity();
		}
	},
	computed: {
		creating() {
			return this.$route.params.id == "create";
		},
		id() {
			return this.$route.params.id;
		},
	},
	methods: {
		getEntity() {
			if (!this.creating) {
                this.$http.get("/api/module/users/user/" + this.id).then(x => {
                    this.entity = x.data;
                    this.resetValidation();
                });
            } else {
				this.entity = {
                    
				};
            }
		},
		submit() {
			this.$validator.validate("module.*").then($isValid => {
				if ($isValid) {
                    this.$http.post("/api/module/users/user", this.entity).then(x => {
                        if (x.data === false) {
                            this.showErrorMsg();
                        } else  {
                            if (this.creating) {
                                this.$router.replace("/users/" + x.data);
                            }

                            this.bus.$emit("submit");
                            this.showSuccessMsg();
                            this.getEntity();	
                        }
                    });
				}
			});
		},
		cancel() {
			this.bus.$emit("cancel");
			this.getEntity();
		},
		deleteEntity() {
			if (confirm("Er du sikker på at du vil slette " + this.entity.name + "?")) {
                this.$http.delete("/api/module/users/user/" +  this.id).then(x => {
                    if (x.data === false) {
                        this.showErrorMsg();
                    } else  {
                        this.bus.$emit("deleteEntity");
                        this.showSuccessMsg();
                        this.$router.replace("/users");
                    }
                });
			}
		}
	},
	created() {
		this.getEntity();
	},
	mounted() {
	},
	mixins: [mixin]
};
</script>
