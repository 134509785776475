<template>
    <div class="position-relative has-tool-menu">
		<div class="tool-menu">
			<Breadcrumb />

			<div class="buttons" style="width: 400px">
                <!-- <b-form-select class="users" v-model="entity.user" :options="users" value-field="id" text-field="name"></b-form-select> -->
			</div>
		</div>

        <b-card no-body class="mb-4">
            <b-card-body>
                <div class="row">
                    <b-col cols="12" md="3" lg="2">
                        <FormGroup label="Shippping deadline">
                            <DateFilter :item="entity.shippingDeadline" />
                        </FormGroup>
                    </b-col>
                    <b-col cols="12" md="3" lg="2">
                        <FormGroup label="Usage date">
                            <DateFilter :item="entity.usageDate" />
                        </FormGroup>
                    </b-col>
                    <b-col cols="12" md="3" lg="2">
                        <FormGroup label="Status">
                            <SelectMulti :item="entity.status" />
                        </FormGroup>
                    </b-col>
                    <b-col cols="12" md="3" lg="2">
                        <FormGroup label="Company">
                            <SelectMulti :item="entity.company" />
                        </FormGroup>
                    </b-col>
                    <b-col cols="12" md="3" lg="2">
                        <FormGroup label="Producer">
                            <SelectMulti :item="entity.producers" />
                        </FormGroup>
                    </b-col>
                </div>

            </b-card-body>
        </b-card>

		<ModuleLoader v-if="entity" :entity="entity" :bus="bus"></ModuleLoader>

	</div>
</template>

<script>
import mixin from "../mixins/module";

import DateFilter from '../_ui/tableFilters/date';
import SelectMulti from '../_ui/tableFilters/selectMulti.vue';

export default {
	data: () => ({
        dates: null,
        entity: {
            usageDate: {
                value: null,
                alias: "usageDate"
            },
            shippingDeadline: {
                value: null,
                alias: "shippingDeadline"
            },
            status: {
                value: [],
				values: [
					"New order",
					"Production started",
                    "Partly sent",
					"Sent to Denmark",
					"Cancelled",
					"Production defect"
				],
                alias: "status"
            },
            producers: {
                value: [],
                values: ["Poland", "Thailand"],
                alias: "producers"
            },
            company: {
                value: [],
                values: ["W", "D", "P", "V", "B"],
                alias: "company"
            }
        },
        users: []
    }),
	components: { DateFilter, SelectMulti },
	computed: {},
	methods: {

    },
	created() {

    },
    mounted() {
        this.entity.shippingDeadline.value = {
            start: this.$moment().startOf('month').toDate(),
            end: this.$moment().endOf('month').toDate()
        };
    },
    mixins: [mixin]
};
</script>

<style lang="scss">
    .buttons .form-control.input {
        width: 32%;
        display: inline-block;
    }

    .buttons .users {
        width: 33%;
        display: inline-block;
        vertical-align: top;
    }
</style>
