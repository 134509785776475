<template>
    <b-card-body class="pt-0" v-if="measurements">

        <div class="production-products row" v-for="(item, lineKey) in orderLines" :key="'orderline-' + lineKey">

            <div class="col-12 mb-1">
                <div class="row">
                    <div class="col-6 col-md-4 product d-flex align-items-center">
                        <h3 class="mb-0 mr-3">{{item.product}}</h3>
                        <span v-if="item.variant">
                            Variant: <strong>{{item.variant}}</strong>
                        </span>
                    </div>

                    <div class="col-6 col-md-4">
                        <b-form-group label="Company" label-cols-sm="3" label-cols-md="4" class="mb-0" label-class="px-0 py-0 mt-1 font-weight-bold" style="margin-top: 3px;">
                            <b-form-radio-group v-model="item.company" size="md" buttons button-variant="secondary" internal="Status">
                                <b-radio v-for="company in companies" :key="company" :value="company" button-variant="outline-dark" @change="updateCompany(lineKey, item)">
                                    {{company}}
                                </b-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </div>

                    <div class="col-6 col-md-4 text-right">
                        <b-checkbox v-model="item.shipped" :button-variant="item.shipped ? 'outline-success' : 'outline-danger'" button @change="updateShipping(lineKey, item)">
                            <span v-if="item.shipped" class="font-lg">Sent</span>
                            <span v-else class="font-lg">Sent</span>
                        </b-checkbox>
                    </div>
                </div>
            </div>
                        
            <template v-for="(spec, key) in getItemSpecs(item.productId)">
                <div class="col-6 col-md-4 spec" :key="'spec-' + key" v-if="getSpec(spec, item)">
                    <span style="border: solid 1px #d6d6d6; display: block; padding: 2px 6px;">
                        <span>{{getSpec(spec, item).name}}:</span> <br><strong v-html="getSpec(spec, item).value.replace('\n', '<br>')"></strong>
                    </span>
                </div>
            </template>

            <div class="col-12">
                <h4>
                    Measurements
                    <span v-if="measurements.find(x => x.productId == item.productId)" class="font-sm ml-3 font-weight-normal">Sizes: {{ measurements.find(x => x.productId == item.productId).sizes.join(', ') }}</span>
                </h4>
            </div>
            
            <div class="col-6 col-md-4 measure" v-for="(meas, key) in getProductMeasurements(item.productId, item.variantId)" :key="'measure-' + key">
                <span style="border: solid 1px #d6d6d6; display: block; padding: 2px 6px;">
                    {{meas.name}}: <strong>{{meas.value}}</strong>
                </span>
            </div>
        </div>

    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";
import Editors from "../../mixins/editors";

export default {
    data: () => ({
        specs: [],
        measurements: null,
        contactFields: [],
        additions: [],
        companies: ["W", "D", "P", "V", "B"]
    }),
    components: { ...Editors },
    computed: {
        orderLines() {
            return this.entity.orderLines.filter(x => x.brand && x.productId && this.toLower(x.brand) == this.toLower(this.entity['5d8a057dfa22d0204498933d']));
        }
    },
    methods: {
        toLower(value) {
            if (value) {
                return value.toLowerCase();
            }
            return null;
        },
        getSpecs() {
            this.$http.get("/api/module/suitsyou/production/specs/" + this.entity.Id).then(x => {
                this.specs = x.data;
            });
        },
        getMeasurements() {
            this.$http.post("/api/module/suitsyou/production/measurements/" + this.entity.contactId, { orderLines: this.orderLines }).then(x => {
                this.measurements = x.data;
            });
        },
        getMadeToMeasure() {
            this.$http.post("/api/module/suitsyou/production/madetomeasure/" + this.entity.contactId, this.orderLines.map(e => e.productId)).then(x => {
                this.measurements = x.data;
            });
        },
        getFields() {
			this.$http.get("/api/module/suitsyou/measurements/fields").then(x => {
				this.contactFields = x.data;
			});
		},


        getSpec(spec, item) {

            if (spec["5d13b746f7cb1732200f1c5e"] != "calculation") {
                return {
                    name: spec["5d13b72af7cb1732200f1c5d"],
                    value: item.specs[spec.id] || "&nbsp;"
                }
            }

            return null;
        },
        getItemSpecs(id) {
            var spec = this.specs.find(x => x.productId == id);
            if (spec) {
                return spec.fields;
            }
            return [];
        },

        getProductMeasurements(productId, variantId) {
            if (!variantId) {
                variantId = "";
            }

            var result = this.measurements.find(x => x.productId == productId); //  && x.variantId == variantId
            if (result) {
                return result.measurements;
            }
            return [];
        },

        updateCompany(i, orderLine) {
            this.$http.post("/api/module/suitsyou/production/" + this.entity.Id + "/update-company?orderLine=" + i, orderLine).then(x => {

            });
        },

        updateShipping(i, orderLine) {
            this.$http.post("/api/module/suitsyou/production/" + this.entity.Id + "/update-shipping?orderLine=" + i, orderLine).then(x => {

            });
        }
    },
    created() {

        this.getSpecs();
        this.getFields();
        //this.getMeasurements();
        this.getMadeToMeasure();

        if (this.bus) {
			this.bus.$on('exportOrder', () => {
                var data = {
                    orderId: this.entity.number,
                    contactId: this.entity.contactId,
                    customer: this.entity["5de4d0649663b9475842a5a5"],
                    shippingDeadline: this.entity["5d8a04bafa22d02044989339"],
                    usageDate: this.entity["5d8a04aefa22d02044989338"],
                    orderLines: this.orderLines,
                    specs: this.specs,
                    additions: this.additions,
                    measurements: this.measurements,
                    company: this.entity["61892fbb0b7efb51f8f086f2"]
                };
                this.$http({
                    url: '/api/module/suitsyou/production/export',
                    data: data,
                    method: 'POST',
                    responseType: 'blob', // important
                }).then(x => {
                    this.$fileDownload(x.data, data.orderId + ' Production.xlsx');
                });
			});
		}
    },
    mounted() {},
    mixins: [mixin]
};
</script>
