<template>
    <div class="position-relative has-tool-menu stock-mgmt">
        <div class="tool-menu">
            <Breadcrumb />

            <div class="buttons">
                <ButtonAreas />
            </div>
        </div>

        <b-card no-body>
            <b-card-body class="p-2">

                <div>
                    <label>
                        Lager navn: 
                        <b-input type="text" @input="getLog"></b-input>
                    </label>
                </div>


                <table class="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>

                            <th colspan="2">Reserved</th>

                            <th colspan="4">Company</th>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <th>Reason</th>
                            <th>Product</th>
                            <th>Before</th>
                            <th>After</th>

                            <th colspan="2">Before</th>
                            <th colspan="2">After</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(group, gKey) in log">
                            <tr v-for="item in group" :key="item.id" :class="{ stripe: (gKey % 2) == 1 }">
                                <td>
                                    <router-link :to="'/orders/' + item.order">{{$moment(item.createDate).format('DD-MM-yyyy HH:mm')}}</router-link>
                                </td>
                                <td>
                                    {{item.reason}}
                                </td>
                                <td>
                                    {{item.orderLine}} {{item.product}}
                                </td>
                                <td :style="isPrevious(item)">
                                    <template v-if="item.before">
                                        {{item.before.reservedAmount}}
                                    </template>
                                </td>
                                <td :style="isPrevious(item)">
                                    <template v-if="item.after && !item.company">
                                        {{item.after.reservedAmount}}
                                    </template>
                                </td>
                            
                                <td>{{item.company}}</td>
                                <td :style="isPrevious(item)">
                                    <template v-if="item.before && item.company">
                                        {{getStockAMount(item.before.producers, item.company)}}
                                    </template>
                                </td>
                                <td>{{item.company}}</td>
                                <td :style="isPrevious(item)">
                                    <template v-if="item.after && item.company">
                                        {{getStockAMount(item.after.producers, item.company)}}
                                    </template>
                                </td>
                            </tr>
                        </template>

                    </tbody>
                </table>

            </b-card-body>
        </b-card>

    </div>
</template>

<script>
    export default {
        data: () => ({
            name: "",
            log: []
        }),
        components: {},
        computed: {},
        watch: {

        },
        methods: {
            getLog(search) {
                this.$http.get("/api/stock/log?name=" + search).then(x => {
                    this.name = x.data.name
                    this.log = x.data.log
                })
            },
            isPrevious(item) {
                return item.reason.indexOf('previous') > -1 ? 'color: red;' : ''
            },
            getStockAMount(producers, company) {
                var producer = producers.find(x => x.name == company)
                if (producer) {
                    return producer.stockAmount
                }

                return null
            }
        },
        created() {

        },
        mounted() { }
    };
</script>

<style lang="scss">

    tr.stripe {
        td {
            background-color: #e1e1e1;
        }
    }

</style>