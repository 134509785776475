<template>
	<div class="input-group">
		<flat-pickr
            ref="datePicker"
			v-model="dates"
			:config="config"        
			placeholder="Vælg dato"
			class="form-control"
			@on-change="refresh">
		</flat-pickr>
		<div class="input-group-append">
			<button class="btn btn-secondary" type="button" @click="clear">
				<i class="fa fa-times"></i>
			</button>
		</div>
	</div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/airbnb.css';
import {Danish} from 'flatpickr/dist/l10n/da.js';

export default {
	data: () => ({
		dates: null,
		config:  {
          wrap: true,
          altFormat: 'd-m-Y',
          altInput: true,
          dateFormat: 'Y-m-d',
		  locale: Danish,
		  disableMobile: true,
		  mode: "range",
		  //weekNumbers: true
        },
	}),
	components: { flatPickr },
	computed: {},
	props: ["item"],
	methods: {
		refresh(e) {
			if (e[1]) {
				this.item.value = {
					start: e[0],
					end: e[1]
				};
				this.$nextTick(() => {
					this.$emit("refresh");
				})
			}
		},
		clear() {
			this.dates = [];
			this.item.value = null;
			this.$emit("refresh");
		}
	},
    watch: {
        "item.value"(val, oldVal) {
            if (oldVal == null && val) {
                this.$refs.datePicker.fp.setDate([
                    val.start,
                    val.end,
                ]);
            }
        }
    },
	created() {

	},
	mounted() {

    }
};
</script>