<template>
    <b-card-body v-if="adjustmentRates">
        
        <table class="table table-md mb-3">
            <thead>
                <tr>
                    <!-- <th>Bruger</th> -->
                    <th>Orders</th>
                    <th>Flawless measurements</th>
                </tr>
            </thead>
            <tbody v-if="adjustmentRates">
                <tr>
                    <!-- <td>{{adjustmentRates.owner}}</td> -->
                    <td>{{adjustmentRates.orders}}</td>
                    <td>{{adjustmentRates.data}}%</td>
                </tr>
            </tbody>
        </table>

        <b-form-group class="reason" label-cols="auto" label="Reason">
            <b-form-select v-model="reason" :options="reasons"></b-form-select>
        </b-form-group>

        <table class="table table-md">
            <thead>
                <tr>
                    <th>Adjusted measurement</th>
                    <th>Adjustments</th>
                    <th class="text-right">Sold products</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, i) in adjustments" :key="item.name + '-' + i">
                    <td>{{item.name}}</td>
                    <td>{{item.adjustments}}</td>
                    <td class="text-right">{{item.product}} <strong>({{item.totalProducts}})</strong></td>
                </tr>
            </tbody>
        </table>


    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";
import BarChart from '../charts/bar'

export default {
    data: () => ({
        reason: "",
        reasons: [
            "",
            'Opmåling kontra kundeønske', 
            'Markant vægtforandring', 
            'Tastefejl',
            'Produktionsfejl',
            'Umulig kunde',
            'Andet'
        ],
        adjustmentRates: null,
        adjustments: [],
        chartOptions: {
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        min: 0,
                        max: 100,
                        beginAtZero: true
                    }
                }]
                
            }
        }
    }),
    components: { BarChart },
    watch: {
        entity: {
            deep: true,
            handler() {
                this.getAdjustmentRates();
                this.getAdjustments();
            }
        },
        "reason"() {
            this.getAdjustments();
        }
    },
    computed: {},
    methods: {
        getAdjustmentRates() {
            this.$http.post("/api/module/dashboard/adjustmentrate", {
                dates: this.entity.dates,
                user: this.entity.user,
                franchise: this.entity.franchise
            }).then(x => {
                this.adjustmentRates = x.data;
            });
        },
        getAdjustments() {
            this.$http.post("/api/module/dashboard/adjustments", {
                dates: this.entity.dates,
                reason: this.reason,
                user: this.entity.user,
                franchise: this.entity.franchise
            }).then(x => {
                this.adjustments = x.data;
            });
        }
    },
    created() {
        this.getAdjustmentRates();
        this.getAdjustments();
    },
    mounted() {

    },
    mixins: [mixin]
};
</script>

<style lang="scss">
    .form-group.reason {
        legend {
            padding: 4px 10px !important
        }
    }
</style>
