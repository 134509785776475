<template>
    <div class="measurements">
		<div class="row">
			<div class="col-12 col-md-4 col-lg-3">
				<b-card>
					<div slot="header">
						<i class="fa fa-tags"></i> Produkter
						<div class="card-header-actions">
							<!-- <b-link href="#" class="card-header-action btn-setting">
								<i class="fa fa-cog"></i>
							</b-link> -->
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<ul class="products-nav nav flex-column">
								<li class="nav-item" v-for="product in products" :key="product.Id">
									<div class="nav-link active">
										<b-form-checkbox v-model="selected" :value="{ productId: product.Id, variantId: null }" :unchecked-value="null">
											{{product.name}} <small v-if="product.brand"> / {{product.brand}}</small>
										</b-form-checkbox>
										<button v-if="product.variants && product.variants.length > 0" class="btn btn-sm btn-primary py-0 my-0" v-b-toggle="'variants-' + product.Id">
											Varianter
											<i class="is-open fa fa-chevron-up" />
											<i class="is-closed fa fa-chevron-down" />
										</button>
									</div>

									<b-collapse :id="'variants-' + product.Id" class="" v-if="product.variants && product.variants.length > 0">
										<ul class="flex-column nav pl-4">
											<li class="nav-item" v-for="variant in product.variants" :key="variant.id">
												<div class="nav-link">
													<b-form-checkbox v-model="selected" :value="{ productId: product.Id, variantId: variant.id }" :unchecked-value="null">
														{{variant["5ce2e8e046cd0030dcf950e0"]}}
													</b-form-checkbox>
												</div>
											</li>
										</ul>
									</b-collapse>
								</li>
							</ul>
						</div>
					</div>
				</b-card>
			</div>

			<div class="col-12 col-md-8 col-lg-9">
				<b-card>
					<div slot="header">
						<i class="fa fa-ruler"></i> Tillægsmål
						<div class="card-header-actions">
							<!-- <b-link href="#" class="card-header-action btn-setting">
								<i class="fa fa-cog"></i>
							</b-link> -->
						</div>
					</div>

					<div class="row" v-if="!measurement">
						<div class="col-12 text-right pb-1">
							<button class="btn btn-danger" @click="resetMeasurements()">
								<i class="fa fa-times"></i>
								Nulstil alle tillægsmål
							</button>
						</div>
					</div>

					<div class="measurement row" v-if="measurement">
					
						<div class="col-12 text-right pb-1">
							<button class="btn btn-success" @click="saveMeasurement()">
								<i class="fa fa-save"></i>
								Gem tillægsmål
							</button>

							<button class="btn btn-danger" @click="resetMeasurements()">
								<i class="fa fa-times"></i>
								Nulstil tillægsmål
							</button>
						</div>

						<div class="col-12 col-sm-6" v-for="item in measurementFields" :key="item.id || item.alias">
							<b-input-group class="mt-1">
                                
								<b-form-checkbox :checked="hasMeasurement(item, true)" @change="setMeasurement($event, { alias: item.alias, id: item.id })">
									{{item.name}}
								</b-form-checkbox>
                                
                                <span class="deleteMeasurement" v-if="hasMeasurement(item)" @click="deleteMeasurement(item)">
                                    <i class="fa fa-times"></i>
                                </span>

								<b-input-group-append>
									<b-form-input type="text" placeholder="Navn" :value="getMeasurement(item).name"
										@input="setMeasurement(true, { alias: item.alias, name: $event, id: item.id })"></b-form-input>

									<b-form-input type="number" placeholder="Mål" :value="getMeasurement(item).value" 
										@input="setMeasurement($event != '', { alias: item.alias, value: $event, id: item.id })"></b-form-input>

									<b-form-select :value="getMeasurement(item).calc" class="" 
										@change="setMeasurement(true, { alias: item.alias, calc: $event, id: item.id })">
										<option value="add">Læg til</option>
										<option value="subtract">Træk fra</option>
										<option value="multiply">Multiplicér</option>
									</b-form-select>

									<b-button variant="light" v-if="hasMeasurement(item)" @click="copyField(item)">
										<i class="fa fa-copy"></i>
									</b-button>
								</b-input-group-append>
							</b-input-group>
						</div>

						<!-- 
						<div class="col-12">
							<h4 class="mt-2">Sorter</h4>

							<draggable tag="div" group="fields" ghost-class="ghost" v-model="measurement.Fields" class="row">
								<div v-for="item in measurement.Fields" :key="item.id || item.alias" class="col-12">
									<i style="padding: 10px 12px 8px 9px" class="fa fa-sort sort-col"></i> {{item.name || getFieldName(item.alias)}}
								</div>
							</draggable>
						</div> -->
					</div>


				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		products: [],
		measurements: [],
		contactMeasurements: [],
		mFields: [],
		selected: null,
		measurement: null,
		contactId: null
	}),
	components: {},
	props: ["entity", "bus"],
	watch: {
		selected() {
			if (this.selected) {
				this.getMeasurements();
			} else {
				this.measurement = null;
			}
		}
	},
	computed: {
		measurementFields() {
			var items = [];
			if (this.measurement) {
				this.mFields.forEach(field => {
					var fields = this.measurement.Fields.filter(x => x.alias == field.alias);

					if (fields.length > 0) {
						fields.forEach(item => {
							items.push({
								name: field.name,
								alias: item.alias,
								id: item.id
							});
						});
					} else {
						items.push({
							name: field.name,
							alias: field.alias
						});
					}
				});
			}
			return items;
		}
	},
	methods: {
		getProducts() {
			this.$http.get("/api/module/suitsyou/measurements/products").then(x => {
				this.products = x.data;
			});
		},
		getMeasurements() {
			if (this.selected) {
				var productId = this.selected.productId;
				var variantId = this.selected.variantId;

				this.$http.post("/api/module/suitsyou/product-measurements", {
					ProductId: productId,
					VariantId: variantId,
					ContactId: this.contactId
				}).then(x => {
					this.measurement = x.data;
				});
			}
		},
		getFields() {
			this.$http.get("/api/module/suitsyou/measurements/fields").then(x => {
				this.mFields = x.data;
			});
		},
		getFieldName(alias) {
			return this.mFields.find(x => x.alias == alias).name;
		},
		setMeasurement(e, o) {
			var alias = o.alias;
			var id = o.id;

			var item = this.measurement.Fields.find(x => x.alias == alias && x.id == id);

			if (e === true) {
				if (item) {
					if (o.calc) {
						item.calc = o.calc;
					}
					if (o.value) {
						item.value = o.value;
					}
					if (o.name) {
						item.name = o.name;
					}

                    item.inactive = false;
				} else {
					this.measurement.Fields.push({
						alias: alias,
						id: id,
						value: o.value || 0,
						calc: o.calc || "add",
						name: o.name,
                        inactive: false
					});
				}
			} else {
                item.inactive = true;
                //this.$set(item, "inactive", true);
			}
		},
        deleteMeasurement(o) {
			var alias = o.alias;
			var id = o.id;

            var itemIndex = this.measurement.Fields.findIndex(x => x.alias == alias && x.id == id);
            this.measurement.Fields.splice(itemIndex, 1);
        },
		getMeasurement(field) {
			var item = this.measurement.Fields.find(x => x.alias == field.alias && x.id == field.id);
			if (item) {
				return {
					value: item.value,
					calc: item.calc,
					name: item.name,
					alias: item.alias,
					id: item.id
				};
			}
			return {
				value: null,
				calc: "add",
				name: null,
				alias: field.alias,
			};
		},
		hasMeasurement(field, isCheck) {
			var item = this.measurement.Fields.find(x => x.alias == field.alias && x.id == field.id);
			if (item && (!isCheck || !item.inactive)) {
				return true;
			}
			return false;
		},
		async copyField(field) {
			this.measurement.Fields.push({
				alias: field.alias,
				value: null,
				calc: "add",
				name: null,
				id: await this.createId()
			});
		},
		saveMeasurement() {
			this.$http.post("/api/module/suitsyou/measurement/update", this.measurement).then(x => {
				this.selected = null;
				this.measurement = null;
			});
		},
		saveContactMeasurement(e) {
			this.$http.post("/api/module/suitsyou/measurement/update/" + this.contactId).then(x => {
				this.selected = null;
			});
		},
		resetMeasurements() {
			this.$http.post("/api/module/suitsyou/measurement/reset", {
				ProductId: this.selected ? this.selected.productId : null,
				VariantId: this.selected ? this.selected.variantId : null,
				ContactId: this.contactId
			}).then(x => {
				this.getMeasurements();
			});
		},
	},
	created() {
		if (this.entity && this.entity.Id) {
			this.contactId = this.entity.Id;
		}

		this.getProducts();
		this.getFields();

		if (this.bus && this.contactId) {
			this.bus.$on('submit', this.saveContactMeasurement);
		}
	},
	mounted() {}
};
</script>