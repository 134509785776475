<template>
	<div>
		<TextEditor :entity="productType" label="Navn" alias="name" :validate="true" scope="field"></TextEditor>

		<!--<FormGroup label="Type" label-cols-md="3">
			<b-form-select :options="editorTypes" v-model="field['5d13b746f7cb1732200f1c5e']" class="">
				<template slot="first">
					<option :value="null"></option>
				</template>
			</b-form-select>
		</FormGroup>-->

        <!--<FormGroup v-if="field.invoicing" label="Muligheder" scope="module" label-cols-md="3">		
			<multiselect 
				class="optify-select"
				:options="tagValues" 
				:multiple="true"
				:taggable="false"
				v-model="field.invoiceValues"
				:close-on-select="false">
			</multiselect>
		</FormGroup>-->

	</div>
</template>

<script>
import TextEditor from "../../_ui/editors/text";
import SelectEditor from "../../_ui/editors/select";

export default {
	data: () => ({
		productType: {},

		//editorTypes:[
		//	{ text: "Læs kun", value: "readonly" },
		//],
	}),
	components: { TextEditor, SelectEditor },
	watch: {
	},
	computed: {},
	props: ["item"],
	methods: {
		ok() {
			this.$http.post("/api/madetomeasure/product-types", this.productType).then(x => {
				this.$emit("close");
			})
		},
		cancel() {
			this.$emit("close");
		}
	},
	created() {
		if (this.item) {
			this.productType = {...this.item}
		}
	},
	mounted() {},
};
</script>