import "./style.scss";

import Vue from 'vue'
const routes = [];
//routes.push({ path: "organizations", redirect: "organizations/ting" });
const RouterView = { render (c) { return c('router-view') } };

import QuickActions from "./quickActions";
Vue.component("qa-organizations", QuickActions);

import details from "./areas/details";
Vue.component("organizationDetails", details);

import Organizations from "./organizations";
import Organization from "./organization";

routes.push({ path: "organizations", component: RouterView, meta: { label: "Events" }, children: [
    { path: "", component: Organizations, name: "organizations", meta: { module: "organizations" } },
    { path: ":id", component: Organization, name: "organization", meta: { label: "Event", module: "organizations" } }
] });

import store from './store';

export { routes, store };
