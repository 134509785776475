<template>
    <b-card-body class="pt-0">
        <div class="row">
            <div class="col-12">
                <b-card no-body class="mb-1" v-for="(item, key) in history" :key="'history-' + key">
                    <b-card-header header-tag="header" class="">
                        <div class="row">
                            <div class="col-sm-6" v-b-toggle="'history-' + key">
                                {{$moment(item.date).format("DD-MM-YYYY HH:mm")}}
                            </div>
                            <!-- <div class="col-sm-7 text-right">
                                <button @click="restoreVersion(item.id)" class="btn btn-danger btn-sm ml-1" style="padding: 1px 6px;">
                                    <span class="fa fa-trash"></span>
                                </button>
                            </div> -->
                        </div>
                    </b-card-header>

                    <b-collapse :id="'history-' + key" accordion="history">
                        <b-card-body>
                            <div class="row">
                                <div class="col-sm-5">
                                    <h5>Measurements</h5>

                                    <div class="col">
                                        <b-form-group v-for="m in item.measurements" :label="m.name" :key="m.name" label-cols="8" label-cols-sm="8" label-cols-md="7" label-cols-lg="6" label-cols-xl="5" class="mt-2" label-class="px-1 py-0 font-weight-bold">
                                            {{m.value}}
                                        </b-form-group>
                                    </div>

                                    <div class="col mt-3" v-for="m in item.madeToMeasure" :key="m.SizeId">
                                        <h5>{{m.ProductType}}</h5>

                                        <b-form-group v-for="f in m.Fields" :label="f.Alias" :key="f.Alias" label-cols="8" label-cols-sm="8" label-cols-md="7" label-cols-lg="6" label-cols-xl="5" class="mt-2" label-class="px-1 py-0 font-weight-bold">
                                            {{f.Total}}
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="col-sm-7">
                                    <h5>Products</h5>
                                    <table v-for="(line, key) in item.orderLines" :key="'line-' + key" class="mb-3 table table-sm table-bordered">
                                        <tr>
                                            <th class="px-2"><h4 class="m-0">{{line.product}}</h4></th>
                                            <td class="p-2"><strong>Amount:</strong> {{line.quantity}}</td>
                                            <td class="p-2"><strong>Price:</strong> {{line.totalPrice}}</td>
                                        </tr>

                                        <tr>
                                            <td colspan="3" class="px-2">
                                                <div class="row">
                                                    <div class="col-sm-4" v-for="(spec, specKey) in line.specs" :key="'spec-' + specKey">
                                                        <b-form-group :label="specKey"
                                                                      label-cols="8" label-cols-sm="8" label-cols-md="7" label-cols-lg="6" label-cols-xl="5"
                                                                      class="mt-2" label-class="px-1 py-0 font-weight-bold">
                                                            {{spec}}
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr v-if="line.measurements || line.mtm">
                                            <th class="px-2" colspan="3">
                                                <h5 class="m-0">
                                                    Measurements
                                                    <small v-if="line.mtm" class="ml-4">Sizes: {{line.mtm.Sizes.join(', ')}}</small>
                                                </h5>
                                            </th>
                                        </tr>

                                        <tr v-if="line.measurements">
                                            <td colspan="3" class="px-2">
                                                <div class="row">
                                                    <div class="col-sm-4" v-for="(measure, measureKey) in line.measurements" :key="'measure-' + measureKey">
                                                        <b-form-group :label="measure.Name"
                                                                      label-cols="8" label-cols-sm="8" label-cols-md="7" label-cols-lg="6" label-cols-xl="5"
                                                                      class="mt-2" label-class="px-1 py-0 font-weight-bold">
                                                            {{measure.Value}}
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        
                                        <tr v-if="line.mtm">
                                            <td colspan="3" class="px-2">
                                                <div class="row">
                                                    <div class="col-sm-4" v-for="(mtm, mtmKey) in line.mtm.Measurements" :key="mtmKey + '-' + mtm.FieldAlias">
                                                        <b-form-group :label="mtm.Name"
                                                                      label-cols="8" label-cols-sm="8" label-cols-md="7" label-cols-lg="6" label-cols-xl="5"
                                                                      class="mt-2" label-class="px-1 py-0 font-weight-bold">
                                                            {{mtm.Value}}
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                    </table>
                                </div>
                            </div>

                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </b-card-body>
</template>

<script>
    import mixin from "../../mixins/area";

    export default {
        data: () => ({
            history: []
        }),
        components: {},
        computed: {},
        methods: {
            getHistory() {
                if (this.entity.Id) {
                    this.$http.get("/api/module/orders/history/" + this.entity.Id).then(x => {
                        this.history = x.data;
                    });
                }
            }
        },
        created() {
            this.getHistory();

            if (this.bus && this.entity && this.entity.Id) {
                this.bus.$on('submit', this.getHistory());
            }
        },
        mounted() { },
        mixins: [mixin]
    };
</script>