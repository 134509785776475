<template>
    <b-card-body>
        <FormGroup alias="name" scope="module" label-cols-md="3" v-slot:default="props">
            <b-form-input type="text" v-model="entity[props.alias]" :state="props.state" :data-vv-scope="props.scope"
                v-validate="'required'" :name="props.alias" :data-vv-as="field(props.alias).name" autocomplete="off" v-secret="entity.ClientId"></b-form-input>
        </FormGroup>

        <FormGroup label="Følgere" scope="module" label-cols-md="3">
            <OptifySelect :options="$users" v-model="entity.followers" :multiple="true" label="name" track-by="id" autocomplete="off"></OptifySelect>
        </FormGroup>

    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";

export default {
    data: () => ({}),
    components: {},
    computed: {},
    methods: {},
    created() {
    },
    mounted() {},
    mixins: [mixin]
};
</script>