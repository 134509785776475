<template>
    <div class="position-relative has-tool-menu">
		<div class="tool-menu">
			<Breadcrumb />

			<div class="buttons" style="width: 500px">
                <b-form-select v-if="franchises.length > 1" class="users" v-model="entity.franchise" :options="franchises" value-field="id" text-field="name"></b-form-select>

                <b-form-select class="users" v-model="entity.user" :options="users" value-field="id" text-field="name"></b-form-select>

                <flat-pickr
                    v-model="dates"
                    :config="config"        
                    placeholder="Vælg dato"
                    class=""
                    @on-change="changeDate">
                </flat-pickr>

                <input class="form-control input" id="secondRangeInput" placeholder="Vælg dato" tabindex="0" type="text" readonly="readonly">
			</div>
		</div>

		<ModuleLoader v-if="entity" :entity="entity" :bus="bus"></ModuleLoader>

	</div>
</template>

<script>
import mixin from "../mixins/module";

import flatPickr from 'vue-flatpickr-component';
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/airbnb.css';
import {Danish} from 'flatpickr/dist/l10n/da.js';

export default {
	data: () => ({
        dates: null,
        entity: {
            dates: null,
            user: null,
            franchise: null,
        },
		config:  {
          wrap: false,
          altFormat: 'd-m-Y',
          altInput: true,
          dateFormat: 'Y-m-d',
		  locale: Danish,
		  disableMobile: true,
		  mode: "range",
          weekNumbers: true,
          plugins: [new rangePlugin({ input: "#secondRangeInput"})]
        },
        users: [],
        franchises: []
    }),
	components: { flatPickr },
	computed: {},
	methods: {
        changeDate(e) {
			if (e[1]) {
				this.entity.dates = e;
			}
        },
        getUsers() {
            this.$http.get("/api/module/dashboard/users").then(x => {
                this.users = x.data;
                this.users.splice(0, 0, { id: null, name: "Alle" });
            });
        },
        getFranchises() {
            this.$http.get("/api/module/dashboard/franchises").then(x => {
                this.franchises = x.data;
                this.franchises.splice(0, 0, { id: null, name: "Alle" });
            });
        }
    },
	created() {
        this.getUsers();
        this.getFranchises();

        this.entity.dates = [
            this.$moment().startOf('month').toDate(),
            this.$moment().endOf('month').toDate()
        ];
        this.dates = this.entity.dates;
    },
    mounted() {},
    mixins: [mixin]
};
</script>

<style lang="scss">
    .buttons {
        display: flex;
        gap: 0px 7px;
    }

    .buttons .form-control.input {
        display: block;
    }

    .buttons .users {
        display: block;
        vertical-align: top;
    }
</style>