<template>
    <div class="position-relative has-tool-menu">
		<div class="tool-menu">
			<Breadcrumb />

			<div class="buttons">
				<b-button variant="secondary">Fortryd ændringer</b-button>
				<b-button @click="saveChanges" variant="success"><span class="fa fa-save"></span> Gem ændringer</b-button>
			</div>
		</div>

		<b-tabs>
			<b-tab>
				<template slot="title">
					Indstillinger
				</template>	

				<masonry :cols="{default: 2, 768: 1}" :gutter="10" v-if="client">

					<b-card no-body>
						<div slot="header">
							<i class="fa fa-info-circle"></i> Detaljer
						</div>
						<b-card-body>
							<div class="row">
								<div class="col-12 col-xl-6">
									<FormGroup label="Navn" label-cols-md="3" :error="errors.first('name')">
										<b-form-input type="text" v-model="client.name" :state="$isValid('name')"
											v-validate="'required'" name="name" data-vv-as="Navn"></b-form-input>
									</FormGroup>
								</div>
								<div class="col-12 col-xl-6">
									<FormGroup label="Logo" label-cols-md="3" :error="errors.first('logo')">
										<b-form-input type="text" v-model="client.logo" :state="$isValid('logo')"
											name="logo" data-vv-as="Logo"></b-form-input>
									</FormGroup>
								</div>
								<div class="col-12 col-xl-6">
									<FormGroup label="Domæne" label-cols-md="3" :error="errors.first('domain')">
										<b-form-input type="text" v-model="client.domain" :state="$isValid('domain')"
											v-validate="'required'" name="domain" data-vv-as="Domæne"></b-form-input>
									</FormGroup>
								</div>
								<div class="col-12 col-xl-6">
									<FormGroup label="Test Domæne" label-cols-md="3">
										<b-form-input type="text" v-model="client.devDomain"></b-form-input>
									</FormGroup>
								</div>
								<div class="col-12">
									<FormGroup label="Moduler" label-cols-md="3" label-cols-lg="2">
										<multiselect @select="selectModule" @remove="removeModule" 
											:value="selectedModules" :options="modules" :multiple="true" label="name" track-by="id"
											placeholder="Vælg moduler"></multiselect>
									</FormGroup>
								</div>
								<div class="col-12 col-xl-6">
									<FormGroup label="Franchise" label-cols-md="3">
										<b-form-select v-model="client.parentId" :options="clients" value-field="id" text-field="name">
											<template slot="first">
												<option :value="null"></option>
											</template>
										</b-form-select>
									</FormGroup>
								</div>
							</div>
						</b-card-body>
					</b-card>

					<b-card no-body>
						<div slot="header">
							<i class="fa fa-users"></i> Brugere
						</div>
						<div class="table-responsive">
							<table class="table table-striped table-md">
								<thead>
									<tr>
										<th>E-mail</th>
										<th>Rolle</th>
										<th>Accepteret</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, key) in client.users" :key="'user-' + key">
										<td>{{item.email}}</td>
										<td>{{item.roleId}}</td>
										<td>{{item.accepted}}</td>
										<td class="text-right">
											<b-button @click="removeUser(key)" size="sm" variant="danger"><i class="fa fa-times"></i></b-button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div slot="footer">
							<b-button @click="userModal = true; user = {};" type="button" size="sm" variant="primary"><i class="fa fa-plus"></i> Tilføj bruger</b-button>
						</div>
					</b-card>

					<b-card no-body>
						<div slot="header">
							<i class="fa fa-user-lock"></i> Roller
						</div>
						<div class="table-responsive">
							<table class="table table-md" style="width: auto">
								<thead>
									<tr>
										<th v-for="(role, rk) in client.roles" :key="'head-' + rk">
											<i class="fa fa-lock"></i> {{role.name}}
											<b-button v-if="role.alias != 'admin'" class="float-right" @click="removeRole(rk)" size="sm" variant="danger"><i class="fa fa-times"></i></b-button>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td v-for="(role, rk) in client.roles" :key="'cell-' + rk">
											<b-form-select :options="permissions" v-model="role.defaultPermission" class="" style="min-width: 150px" />
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div slot="footer">
							<b-button @click="roleModal = true; role = {};" type="button" size="sm" variant="primary"><i class="fa fa-plus"></i> Tilføj rolle</b-button>
						</div>
					</b-card>

				</masonry>
			</b-tab>

			<b-tab v-for="m in selectedModules" :key="m.id">
				<template slot="title">
					{{m.name}}
					<b-link @click.stop="accessModal({}, m.id)" class="card-header-action btn-setting">
						<i class="fa fa-lock"></i>
					</b-link>
				</template>

				<b-card no-body>
					<div slot="header">
						<i class="fa fa-cogs"></i> Indstillinger
					</div>
					<div class="table-responsive">
						<table class="table table-striped table-md">
							<thead>
								<tr>
									<th>Alias</th>
									<th>Værdi</th>
									<th>Offentlig</th>
									<th>Type</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, key) in getModuleSetting(m.id).settings" :key="'setting-' + key">
									<td>
										<b-form-input type="text" placeholder="Alias" v-model="item.alias" autocomplete="off"></b-form-input>
									</td>
									<td>
										<b-form-input type="text" placeholder="Værdi" v-model="item.value" autocomplete="off"></b-form-input>
									</td>
									<td>
										<b-form-checkbox v-model="item.public">Ja</b-form-checkbox>
									</td>
									<td>{{item.type}}</td>
									<td class="text-right">
										<b-button @click="getModuleSetting(m.id).settings.splice(key, 1)" size="sm" variant="danger"><i class="fa fa-times"></i></b-button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div slot="footer">
						<b-button @click="addSetting(m.id)" type="button" size="sm" variant="primary"><i class="fa fa-plus"></i> Tilføj indstilling</b-button>
					</div>
				</b-card>

				<b-tabs nav-wrapper-class="w-40">
					<b-tab v-for="menu in m.menu" :key="'menu-' + menu.id">
						<template slot="title">
							{{menu.title}}
							<b-link @click.stop="accessModal({ menu: menu }, m.id)" class="card-header-action btn-setting pl-2 pr-0">
								<i class="fa fa-lock"></i>
							</b-link>
						</template>			

						<b-tabs nav-wrapper-class="w-40" class="mb-2 tabs-default">
							<b-tab v-for="(tab, tabKey) in moduleTabs.filter(x => x.moduleId == m.id && x.menuId == menu.id)" :key="'tab-' + tabKey">
								<template slot="title">
									{{tab.name}}
									<b-link @click.stop="accessModal({ area: tab }, m.id)" v-if="tab.alias" class="card-header-action btn-setting pl-2 pr-0">
										<i class="fa fa-lock"></i>
									</b-link>
								</template>

								<template v-for="(layout, layoutKey) in layouts.filter(x => x.moduleId === m.id && x.menuId == menu.id && x.areaAlias == tab.alias)">
									<div class="layout-row row pt-3" v-for="(row, rowKey) in layout.rows" :key="'row-' + rowKey">
										<b-button v-if="rowIsEmpty(row, layout)" @click="removeRow(layout.rows, rowKey)" class="remove-row"><i class="fa fa-times"></i></b-button>
										
										<div v-for="(col, colKey) in row" :class="'layout-col col-12 col-md-' + col.width" :key="'col-' + colKey">

											<div class="layout-col-wrapper">
												<draggable class="draggable-areas" v-model="col.areas" :handle="'.card-header'" group="areas" ghost-class="ghost">
                                                    <ClientArea v-for="area in col.areas" :key="area.areaAlias" :modules="selectedModules" 
														:col-area="area" :module-id="m.id" :menu-id="menu.id" :clientAreas="clientAreas"
														@delete="removeArea(col, $event)" @edit="editArea(col, $event)" @editField="addField" @nullarea="removeNullArea(col, $event)">
														<template slot="roles">
															<th v-for="(role, rk) in client.roles" :key="'cell-' + rk">
																<i class="fa fa-lock"></i> {{role.name}}
															</th>
														</template>
														<template slot-scope="props">
															<td v-for="(role, rk) in client.roles" :key="'cell-' + rk">
																<b-form-select :options="permissions" 
																	:value="getModuleFieldRule(props.item.moduleAlias, props.item.alias, role)" 
																	@input="editModuleFieldRule(props.item.moduleAlias, props.item.alias, role, $event)" 
																	class="" style="min-width: 150px; max-width: 200px;">
																	<template slot="first">
																		<option :value="null"></option>
																	</template>
																</b-form-select>
															</td>
														</template>
														<template slot="access" slot-scope="props">
															<b-link @click.stop="accessModal(props, m.id)" class="card-header-action btn-setting pl-2 pr-0">
																<i class="fa fa-lock"></i>
															</b-link>
														</template>
														<template slot="field" slot-scope="props">
															<b-button @click="addField(props.area)" variant="primary" >
																<i class="fa fa-plus"></i> Tilføj felt
															</b-button>
														</template>
													</ClientArea>
												</draggable>

												<div class="layout-row row pt-3" v-for="(childRow, childRowKey) in col.rows" :key="'child-row-' + childRowKey">
													<b-button v-if="rowIsEmpty(childRow, layout)" @click="removeRow(col.rows, childRowKey)" class="remove-row"><i class="fa fa-times"></i></b-button>

													<div v-for="(childCol, childColKey) in childRow" :class="'layout-col col-12 col-md-' + childCol.width" :key="'child-col-' + childColKey">
														<div class="layout-col-wrapper">
															<draggable v-model="childCol.areas" :handle="'.card-header'" group="areas" ghost-class="ghost">
                                                                <ClientArea v-for="area in childCol.areas" :key="area.areaAlias" :modules="selectedModules" 
																	:col-area="area" :module-id="m.id" :menu-id="menu.id" :clientAreas="clientAreas"
																	@delete="removeArea(childCol, $event)" @edit="editArea(childCol, $event)" @editField="addField" @nullarea="removeNullArea(col, $event)">
																	<template slot="roles">
																		<th v-for="(role, rk) in client.roles" :key="'cell-' + rk">
																			<i class="fa fa-lock"></i> {{role.name}}
																		</th>
																	</template>
																	<template slot-scope="props">
																		<td v-for="(role, rk) in client.roles" :key="'cell-' + rk">
																			<b-form-select :options="permissions" 
																				:value="getModuleFieldRule(props.item.moduleAlias, props.item.alias, role)" 
																				@input="editModuleFieldRule(props.item.moduleAlias, props.item.alias, role, $event)" 
																				class="" style="min-width: 150px; max-width: 200px;">
																				<template slot="first">
																					<option :value="null"></option>
																				</template>
																			</b-form-select>
																		</td>
																	</template>
																	<template slot="access" slot-scope="props">
																		<b-link @click.stop="accessModal(props, m.id)" class="card-header-action btn-setting pl-2 pr-0">
																			<i class="fa fa-lock"></i>
																		</b-link>
																	</template>
																	<template slot="field" slot-scope="props">
																		<b-button @click="addField(props.area)" variant="primary" >
																			<i class="fa fa-plus"></i> Tilføj felt
																		</b-button>
																	</template>
																</ClientArea>
															</draggable>

															<div class="layout-add">
																<b-button @click="areaModal(childCol, m.id, menu.id, tab.alias)" variant="primary"><i class="fa fa-plus"></i> Add area</b-button>
															</div>
														</div>
													</div>
												</div>

												<div class="layout-add">
													<b-form-select class="my-1 w-auto" v-model="newRow" :options="rowLayouts" @change="addRowLayout(col.rows)">
														<template slot="first">
															<option :value="null" disabled>-- Vælg row layout --</option>
														</template>
													</b-form-select>

													<b-button @click="areaModal(col, m.id, menu.id, tab.alias)" variant="primary"><i class="fa fa-plus"></i> Tilføj område</b-button>
												</div>
											</div>
										</div>
									</div>

									<div class="layout-add" :key="layoutKey">
										<b-form-select class="my-1 w-auto" v-model="newRow" :options="rowLayouts" @change="addRowLayout(layout.rows)">
											<template slot="first">
												<option :value="null" disabled>-- Vælg row layout --</option>
											</template>
										</b-form-select>
									</div>

								</template>
							</b-tab>
						</b-tabs>
					</b-tab>
				</b-tabs>
			</b-tab>
		</b-tabs>

		<b-modal ref="userModal" title="Tilføj bruger" cancel-title="Fortryd" :centered="true" v-if="user"
			v-model="userModal" ok-title="Tilføj" @ok="addUser" size="sm" @hidden="user = null">
			<FormGroup label="E-mail" label-cols-md="3" :error="errors.first('email')">
				<b-form-input type="text" v-model="user.email" :state="$isValid('email')"
					v-validate="'required'" name="email" data-vv-as="E-mail"></b-form-input>
			</FormGroup>

			<FormGroup label="Rolle" label-cols-md="3" :error="errors.first('role')" :label-cols="3">
				<b-form-select :options="client.roles" class="" v-model="user.roleId" value-field="alias" text-field="name" >
				</b-form-select>
			</FormGroup>

			<b-form-checkbox v-model="user.accepted">Accepteret</b-form-checkbox>
		</b-modal>
		
		<b-modal ref="roleModal" title="Tilføj rolle" cancel-title="Fortryd" :centered="true" v-if="role"
			v-model="roleModal" ok-title="Tilføj" @ok="addRole" size="sm" @hidden="role = null">
			<FormGroup label="Rollenavn" label-cols-md="3" :error="errors.first('roleName')">
				<b-form-input type="text" v-model="role.name" :state="$isValid('roleName')"
					v-validate="'required'" name="roleName" data-vv-as="Navn"></b-form-input>
			</FormGroup>
		</b-modal>

		<b-modal id="areaModal" title="Område" cancel-title="Fortryd" :centered="true" 
			ok-title="Gem" @ok="saveArea" size="md" @hidden="customArea = null">
			<div v-if="customArea">
				<FormGroup label="Navn" label-cols-md="3">
					<b-form-input type="text" v-model="customArea.data.name"></b-form-input>
				</FormGroup>
				
				<FormGroup label="Alias" label-cols-md="3">
					<b-form-input disabled type="text" v-model="customArea.data.alias"></b-form-input>
				</FormGroup>
			</div>
		</b-modal>

		
		<b-modal id="accessModal" title="Rediger adgang" cancel-title="Fortryd" :centered="true"
			ok-title="Gem" @ok="saveAccessRule" size="sm" @hidden="accessRule = null">
			<div v-if="accessRule">
				<div v-for="(role, rk) in accessRule.roles" :key="'role-' + rk">
					<b-form-group :label="role.name" label-cols-md="3">
						<b-form-select :options="permissions" v-model="role.permission"
							class="" style="min-width: 150px; max-width: 200px;">
							<template slot="first">
								<option :value="null"></option>
							</template>
						</b-form-select>
					</b-form-group>
				</div>
			</div>
		</b-modal>
		
		<b-modal id="fieldModal" title="Rediger felt" @ok="saveField" size="lg" @hidden="fieldArea = null">
			<div class="row" v-if="editField">
				<div class="col-12 col-xl-6">
					<FormGroup label="Navn" label-cols-md="3">
						<b-form-input type="text" v-model="editField.name"></b-form-input>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Alias" label-cols-md="3">
						<b-form-input disabled type="text" v-model="editField.alias"></b-form-input>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Type" label-cols-md="3">
						<b-form-select :options="editorTypes" v-model="editField.type" class="">
							<template slot="first">
								<option :value="null"></option>
							</template>
						</b-form-select>
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6">
					<FormGroup label="Filtrerbar" label-cols-md="3">
						<b-form-checkbox v-model="editField.filterable" class="mt-1" />
					</FormGroup>
				</div>
				<div class="col-12 col-xl-6" v-if="editField.type == 'select' || editField.type == 'selectMulti'">
					<FormGroup label="Værdier" label-cols-md="3">
						<b-input-group>
							<b-form-input :ref="'value-' + editField.alias"></b-form-input>
							<b-input-group-append>
								<b-btn variant="success" @click="addValue(editField, 'value-' + editField.alias)">Tilføj</b-btn>
							</b-input-group-append>
						</b-input-group>
						<p class="p-1 m-0" v-for="(val, idx) in editField.values" :key="idx">{{val}} <i class="fa fa-times" @click="editField.values.splice(idx, 1)"></i></p>
					</FormGroup>
				</div>
			</div>
		</b-modal>

	</div>
</template>

<script>
import ClientArea from "../_ui/clientArea.vue";

export default {
	data: () => ({
		moduleTab: null,
		client: {
			name: null,
			logo: null,
			domain: null,
			devDomain: null,
			modules: [],
			users: [],
			roles: []
		},
		clientModuleSettings: [],
		layouts: [],
		moduleTabs: [],
		modules: [],
		userModal: false,
		user: null,
		roles: [],
		roleModal: false,
		role: null,
		clientAreas: [],
		rowLayouts: [
			{ text: "12", value: [
				{ width: 12, rows:[], areas:[], class: '' }
			] },
			{ text: "4+8", value: [
				{ width: 4, rows:[], areas:[], class: '' },
				{ width: 8, rows:[], areas:[], class: '' }
			] },
			{ text: "8+4", value: [
				{ width: 8, rows:[], areas:[], class: '' },
				{ width: 4, rows:[], areas:[], class: '' }
			] },
			{ text: "6+6", value: [
				{ width: 6, rows:[], areas:[], class: '' },
				{ width: 6, rows:[], areas:[], class: '' }
			] },
			{ text: "4+4+4", value: [
				{ width: 4, rows:[], areas:[], class: '' },
				{ width: 4, rows:[], areas:[], class: '' },
				{ width: 4, rows:[], areas:[], class: '' }
			] },
		],
		newRow: null,
		customArea: null,
		fieldArea: null,
		editField: null,
		accessRule: null,
		clients: []
	}),
	components: { ClientArea },
	computed: {
		clientId() {
			return this.$route.params.id;
		},
		editing() {
			return this.$route.params.id != null;
		},
		selectedModules() {
			return this.modules.filter(x => this.client.modules.includes(x.id));
		},
		selectedRole() {
			return this.roles.filter(x => this.user.roleId == x.value);
		},
		permissions() {
			var result = Object.entries(this.$store.state.permissions).map(([value, text]) => {
				return {value, text};
			});
			return result;				
		},
		editorTypes() {
			return this.$store.state.editorTypes;
		}
	},
	watch: {
		async selectedModules(modules) {			
			this.moduleTabs = [];
			modules.forEach(module => {
				module.menu.forEach(menu => {
					var defaultTab = { name: "Standard", alias: null, moduleId: module.id, menuId: menu.id };
					var tabs = modules.flatMap(x => x.areas).filter(x => x.menuId == menu.id && x.areaType == "tab" && x.moduleArea == null).map(x => {
						return { name: x.name, alias: x.alias, moduleId: module.id, menuId: x.menuId };
					});
					tabs.splice(0, 0, defaultTab);

					this.moduleTabs.push(...tabs);

					tabs.forEach(tab => {
						var layout = this.layouts.find(x => x.moduleId == module.id && x.menuId == menu.id && x.areaAlias == tab.alias);
						if (layout == null) {
							layout = {
								moduleId: module.id,
								menuId: menu.id,
								areaAlias: tab.alias,
								rows: []
							};
						}

						var areas = modules.flatMap(x => x.areas).filter(x => x.menuId == menu.id && x.areaType == "area" && x.moduleArea == tab.alias);
												
						var layoutAreas = layout.rows.flatMap(r => 
							r.flatMap(x => x.areas).concat(
								r.flatMap(x => x.rows).flatMap(x => x).flatMap(x => x.areas)
							)
						);

						areas.forEach(area => {
							var colModule = layoutAreas.find(x => x.areaAlias == area.alias);
							if (colModule == null) {
								var width = area.width > 0 ? area.width : 6;
								layout.rows.push([
									{ width: width, rows:[], areas:[
										{ areaAlias: area.alias, isCustom: false, moduleId: null }
									] }
								]);
							}
						});

						if (layout.id == null) {
							this.layouts.push(layout);
						}
					});
				});
			});
		}
	},
	methods: {
		async getItem() {
			await this.$http.get("/api/admin/client/" + this.$route.params.id + "/areas").then(x => {
				this.clientAreas = x.data;
			});

			await this.$http.get("/api/admin/client/" + this.$route.params.id + "/layouts").then(x => {
				this.layouts = x.data;
				if (this.layouts == null) {
					this.layouts = [];
				}
			});

			await this.$http.get("/api/admin/client/" + this.$route.params.id).then(x => {
				this.client = x.data;
				if (!this.client.modules)
					this.client.modules = [];
				if (!this.client.users)
					this.client.users = [];

				if (this.client.roles.length <= 0) {
					this.client.roles.push({
						name: "Admin",
						alias: "admin",
						defaultPermission: 60,
						rules: []
					});
				}
			});
		},
		selectModule(item) {
			this.client.modules.push(item.id);
			if (item.requiredModules) {
				item.requiredModules.forEach(x => {
					if (!this.client.modules.includes(x)) {
						this.client.modules.push(x);
					}
				});
			}
		},
		removeModule(item) {
			var canRemove = true;

			this.selectedModules.forEach(x => {
				if (x.requiredModules && x.requiredModules.includes(item.id)) {
					canRemove = false;
				}
			});

			if (canRemove) {
				var i = this.client.modules.indexOf(item.id);
				this.client.modules.splice(i, 1);

				this.client.roles.forEach(role => {
					var ri = role.rules.findIndex(e => e.moduleId == item.id);
					role.rules.splice(ri, 1);
				});

				this.clientAreas.filter(e => e.moduleId == item.id).forEach((x, i) => {
					var cfi = this.clientAreas.findIndex(e => e.alias == x.alias);					
					this.clientAreas.splice(cfi, 1);
				});

			}
		},

		addUser() {
			this.client.users.push({
				email: this.user.email,
				roleId: this.user.roleId,
				accepted: this.user.accepted
			});
		},
		removeUser(item) {
			this.client.users.splice(item, 1);
		},

		addRole() {
			this.client.roles.push({
				name: this.role.name,
				alias: this.role.name.toLowerCase(),
				defaultPermission: null,
				rules: []
			});
		},
		removeRole(item) {
			this.client.roles.splice(item, 1);
		},
		getModuleRule(moduleId, role) {
			var moduleRule = role.rules.find(x => x.moduleId == moduleId);			
			return moduleRule ? moduleRule.defaultPermission : null;
		},
		editModuleRule(moduleId, role, val) {
			var moduleRule = role.rules.find(x => x.moduleId == moduleId);
			if (moduleRule) {
				moduleRule.defaultPermission = val;
			} else {
				role.rules.push({
					moduleId: moduleId,
					defaultPermission: val,
					areaRules: [],
					fieldRules: []
				});
			}
		},
		getMenuRule(moduleId, menuId, role) {
			var moduleRule = role.rules.find(x => x.moduleId == moduleId);
			if (moduleRule) {
				var menu = moduleRule.menuRules.find(x => x.menuId == menuId);
				return menu ? menu.permission : null;
			}
			return null;
		},
		editMenuRule(moduleId, menuId, role, val) {
			var moduleRule = role.rules.find(x => x.moduleId == moduleId);
			if (moduleRule) {
				var menu = moduleRule.menuRules.find(x => x.menuId == menuId);
				if (menu) {
					menu.permission = val;
				} else {
					moduleRule.menuRules.push({
						menuId: menuId,
						permission: val
					});
				}
			} else {
				role.rules.push({
					moduleId: moduleId,
					defaultPermission: role.defaultPermission,
					menuRules: [{
						menuId: menuId,
						permission: val
					}],
					areaRules: [],
					fieldRules: [],
				});
			}
		},
		getModuleAreaRule(moduleId, areaId, role) {
			var moduleRule = role.rules.find(x => x.moduleId == moduleId);

			if (moduleRule) {
				var area = moduleRule.areaRules.find(x => x.areaId == areaId);
				return area ? area.permission : null;
			}
			return null;
		},
		editModuleAreaRule(moduleId, areaId, role, val) {
			var moduleRule = role.rules.find(x => x.moduleId == moduleId);
			if (moduleRule) {
				var area = moduleRule.areaRules.find(x => x.areaId == areaId);
				if (area) {
					area.permission = val;
				} else {
					moduleRule.areaRules.push({
						areaId: areaId,
						permission: val
					});
				}
			} else {
				role.rules.push({
					moduleId: moduleId,
					defaultPermission: role.defaultPermission,
					areaRules: [{
						areaId: areaId,
						permission: val
					}],
					menuRules: [],
					fieldRules: []
				});
			}
		},
		getModuleFieldRule(moduleId, fieldId, role) {
			var moduleRule = role.rules.find(x => x.moduleId == moduleId);

			if (moduleRule) {
				var field = moduleRule.fieldRules.find(x => x.fieldAlias == fieldId);
				return field ? field.permission : null;
			}
			return null;
		},
		editModuleFieldRule(moduleId, fieldId, role, val) {
			var moduleRule = role.rules.find(x => x.moduleId == moduleId);
			if (moduleRule) {
				var field = moduleRule.fieldRules.find(x => x.fieldAlias == fieldId);
				if (field) {
					field.permission = val;
				} else {
					moduleRule.fieldRules.push({
						fieldAlias: fieldId,
						permission: val
					});
				}
			} else {
				role.rules.push({
					moduleId: moduleId,
					defaultPermission: role.defaultPermission,
					fieldRules: [{
						fieldAlias: fieldId,
						permission: val
					}],
					menuRules: [],
					areaRules: []
				});
			}
		},

		getSelectedModuleAreas(moduleId) {
			var module = this.selectedModules.find(x => x.id == moduleId);
			if (module) {
				return module.areas;
			}
			return [];
		},

		accessModal(props, moduleId) {		
			this.accessRule = { 
				...props, 
				moduleId, 
				roles: this.client.roles.flatMap(role => { 
					var permission = null;
					
					if (props.area) {
						permission = this.getModuleAreaRule(moduleId, props.area.alias, role)
					} else if (props.menu) {
						permission = this.getMenuRule(moduleId, props.menu.id, role)
					} else {
						permission = this.getModuleRule(moduleId, role)
					}
					
					return { 
						...role, 
						permission: permission 
					} 
				})
			};			
			this.$root.$emit('bv::show::modal','accessModal');
		},
		saveAccessRule() {			
			this.accessRule.roles.forEach(role => {
				if (role.permission !== undefined) {
					if (this.accessRule.area) {
						this.editModuleAreaRule(this.accessRule.moduleId, this.accessRule.area.alias, role, role.permission)
					} else if (this.accessRule.menu) {
						this.editMenuRule(this.accessRule.moduleId, this.accessRule.menu.id, role, role.permission)
					} else {
						this.editModuleRule(this.accessRule.moduleId, role, role.permission)
					}
				}
			});
		},

		addRowLayout(rows) {
			rows.push(this.newRow);
			this.newRow = null;
		},
		async areaModal(col, moduleId, menuId, areaAlias) {
			this.customArea = {
				data: {
					moduleId: moduleId,
					menuId: menuId,
					name: "Nyt område",
					alias: await this.createId(),
					areaAlias: areaAlias,
					width: 6,
					customFields: []
				},
				col: col
			};

			this.$root.$emit('bv::show::modal','areaModal');
		},
		saveArea() {
			var area = this.clientAreas.find(x => x.alias == this.customArea.data.alias);
			if (!area) {
				this.clientAreas.push(this.customArea.data);
				this.customArea.col.areas.push({
					moduleId: this.customArea.data.moduleId,
					areaAlias: this.customArea.data.alias,
					isCustom: true
				});
			} else {
				area.name = this.customArea.data.name;
			}
			this.customArea = null;
		},
		editArea(col, model){
			var area = this.clientAreas.find(x => x.menuId == model.menuId && x.alias == model.alias);
			this.customArea = {
				data: Object.assign({}, area),
				col: col
			};

			this.$root.$emit('bv::show::modal','areaModal');
		},
		removeArea(col, model) {
			var areaIdx = this.clientAreas.findIndex(x => x.menuId == model.menuId && x.alias == model.alias);
			var area = this.clientAreas[areaIdx];
			if (area) {
				if (area.alias == "general") {
					return;
				}
				var idx = col.areas.findIndex(x => x.areaAlias == model.alias);
				col.areas.splice(idx, 1);
				this.clientAreas.splice(areaIdx, 1);
			}			
		},
		removeNullArea(col, model) {
			var areaIdx = col.areas.findIndex(x => x.areaAlias == model.alias);
            if (areaIdx > -1) {
                col.areas.splice(areaIdx, 1);
            }
		},
		rowIsEmpty(row, layout) {
			var isEmpty = true;

			var areas = this.selectedModules.flatMap(x => x.areas);

			row.forEach(col => {
				if (col.areas.length != 0) {
					col.areas.forEach(area => {
						var findArea = areas.find(x => x.menuId == layout.menuId && x.alias == area.areaAlias);
						if (findArea) {
							isEmpty = false;
						}
					});
				}
				col.rows.flat().forEach(x => {
					if (x.areas.length != 0) {
						x.areas.forEach(area => {
							var findArea = areas.find(x => x.menuId == layout.menuId && x.alias == area.areaAlias);
							if (findArea) {
								isEmpty = false;
							}
						});
					}
				});
			});
			return isEmpty;
		},
		removeRow(container, key) {
			container.splice(key, 1);
		},


		async addField(area, field) {
			this.$root.$emit('bv::show::modal','fieldModal');

			if (field) {
				this.editField = Object.assign({}, field);
			} else {
				this.editField = {
					name: null,
					alias: await this.createId(),
					type: null,
					values: [],
					filterable: false
				};
			}

			this.fieldArea = area;
		},
		saveField() {
			var field = this.fieldArea.customFields.findIndex(x => x.alias == this.editField.alias);
			if (field == -1) {
				this.fieldArea.customFields.push(this.editField);
			} else {
				this.$set(this.fieldArea.customFields, field, this.editField)
			}
			this.editField = null;
			this.fieldArea = null;
		},
		addValue(item, key) {
			item.values.push(this.$refs[key].$el.value);
			this.$refs[key].$el.value = null;
		},

		getModuleSetting(id){
			var setting = this.clientModuleSettings.find(x => x.moduleId == id);

			if (setting == null) {
				setting = {
					clientId: this.clientId,
					moduleId: id,
					settings: []
				}

				this.clientModuleSettings.push(setting);
			}

			return setting;
		},
		addSetting(id) {
			var setting = this.clientModuleSettings.find(x => x.moduleId == id);

			setting.settings.push({
				alias: "",
				value: null,
				type: null
			});
		},


		saveChanges() {
			var updateClient = {
				client: this.client,
				clientAreas: this.clientAreas,
				layouts: this.layouts,
				settings: this.clientModuleSettings
			};

			this.$http.post("/api/admin/clients/update", updateClient).then(x => {
				if (x.data !== false) {
					var id = x.data || this.$route.params.id;

					this.showSuccessMsg();

					if (!this.editing) {
						this.$router.replace("/admin/clients/" + id)
					}
				} else {
					this.showErrorMsg();
				}
			});
		}
	},
	async created() {
		await this.$http.get("/api/admin/module-list").then(x => {
			this.modules = x.data;
		});

		await this.$http.get("/api/admin/client/clients").then(x => {
			this.clients = x.data;
		});

		await this.$http.get("/api/admin/client/" + this.clientId + "/settings").then(x => {
			this.clientModuleSettings = x.data;
		});

		if (this.editing) {
			await this.getItem();
		}
	},
	mounted() {}
};
</script>