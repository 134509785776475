<template>
    <b-card-body v-if="result">        
        
        <table class="table table-md mb-3">
            <thead>
                <tr>
                    <th>Produkter</th>
                    <th>Antal</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in result" :key="item.product">
                    <td>{{item.product}}</td>
                    <td>{{item.count}}</td>
                </tr>
            </tbody>
        </table>

    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";

export default {
    data: () => ({
        result: null
    }),
    components: {},
    watch: {
        entity: {
            deep: true,
            handler() {
                this.getResult();
            }
        }
    },
    computed: {},
    methods: {
        getResult() {
            this.$http.post("/api/module/suitsyou/production/dashboard/items", this.entity).then(x => {
                this.result = x.data;
            });
        },
    },
    created() {
        this.getResult();
    },
    mounted() {

    },
    mixins: [mixin]
};
</script>

<style lang="scss">


</style>