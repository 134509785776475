<template>
    <div class="madetomeasure">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3">
                <b-card>
                    <div slot="header">
                        <i class="fa fa-tags"></i> Product types
                        <div class="card-header-actions">
                            <b-link href="#" @click="productTypeModal()" class="text-dark card-header-action btn-setting">
                                <i class="fa fa-plus"></i>
                                Create
                            </b-link>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <ul class="products-nav nav flex-column">
                                <li class="nav-item" v-for="type in productTypes" :key="type.Id">
                                    <div class="nav-link active">
                                        <b-form-checkbox v-model="selected" :value="type.id" :unchecked-value="null">
                                            {{type.name}}
                                        </b-form-checkbox>

                                        <button @click="productTypeModal(type)" style="right: 70px;" class="btn btn-sm btn-primary py-0 my-0">
                                            Edit
                                            <i class="fa fa-edit" />
                                        </button>
                                        <button @click="copyProductType(type)" style="right: 35px;" class="btn btn-sm btn-success py-0 my-0">
                                            <i class="fa fa-copy" />
                                        </button>
                                        <button @click="deleteProductType(type)" class="btn btn-sm btn-danger py-0 my-0">
                                            <i class="fa fa-trash" />
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </b-card>
            </div>

            <div class="col-12 col-md-8 col-lg-9">
                <b-card>
                    <div slot="header">
                        <div class="d-flex">
                            <div class="">
                                <i class="fa fa-ruler"></i> Standard measurements
                                <span v-if="productType"> - {{productType.name}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="productType">
                        <div class="col-12 mb-1 text-right pb-1">
                            <button class="btn btn-primary mr-3" @click="sizeModal()">
                                <i class="fa fa-plus"></i>
                                Create size
                            </button>

                            <button class="btn btn-success" @click="saveSizes()">
                                <i class="fa fa-times"></i>
                                Save measurements
                            </button>
                        </div>

                    </div>

                    <draggable v-if="productType" tag="div" group="fields" ghost-class="ghost" v-model="productType.sizes" class="row">
                        <div class="measurement col-12 mb-2" :key="'size-' + key" v-for="(size, key) in productType.sizes">
                            <b-button class="text-left d-flex justify-content-between" block v-b-toggle="'size-' + key" variant="light">
                                <div>
                                    Size: <strong>{{size.name}}</strong>
                                </div>

                                <div class="">
                                    <button @click.stop="sizeModal(size, key)" class="mr-2 btn btn-sm btn-primary py-0 my-0">
                                        Edit
                                        <i class="fa fa-edit" />
                                    </button>
                                    <button @click.stop="copySize(size)" class="mr-2 btn btn-sm btn-success py-0 my-0">
                                        <i class="fa fa-copy" />
                                    </button>
                                    <button @click.stop="productType.sizes.splice(key, 1)" class="btn btn-sm btn-danger py-0 my-0">
                                        <i class="fa fa-trash" />
                                    </button>
                                </div>
                            </b-button>
                            <b-collapse :id="'size-' + key" accordion="sizes" class="">

                                <div class="row">
                                    <div class="col-12 col-sm-6" v-for="item in sizeFields[size.name]" :key="item.alias">
                                        <b-input-group class="mt-1">

                                            <b-form-checkbox :checked="hasSize(size, item)" @change="setSize(size, { alias: item.alias }, $event)">
                                                {{item.name}}
                                            </b-form-checkbox>

                                            <b-input-group-append>
                                                <b-form-input type="text" placeholder="Name" :value="getSize(size, item).name"
                                                              @input="setSize(size, { alias: item.alias, name: $event })"></b-form-input>

                                                <b-form-input type="text" placeholder="Measure" :value="getSize(size, item).value"
                                                              @input="setSize(size, { alias: item.alias, value: $event })"></b-form-input>

                                                <b-form-input type="text" placeholder="Note/Guide" :value="getSize(size, item).note"
                                                              @input="setSize(size, { alias: item.alias, note: $event })"></b-form-input>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </div>

                            </b-collapse>
                        </div>
                    </draggable>



                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
    import productTypeModal from "../modals/_productType";
    import sizeModal from "../modals/_size";

    export default {
        data: () => ({
            fields: [],
            productTypes: [],
            selected: null,
            productType: null
        }),
        components: {},
        props: ["bus"],
        watch: {
            selected(val) {
                if (val) {
                    this.getProductType(val)
                } else {
                    this.productType = null
                }
            }
        },
        computed: {
            sizeFields() {
                var sizes = {};
                if (this.productType) {
                    this.fields.forEach(field => {
                        this.productType.sizes.forEach(size => {
                            if (sizes[size.name] == null) {
                                sizes[size.name] = []
                            }

                            var fields = size.fields.filter(x => x.alias == field.alias)

                            if (fields.length > 0) {
                                fields.forEach(item => {
                                    sizes[size.name].push({
                                        name: field.name,
                                        alias: item.alias
                                    });
                                });
                            } else {
                                sizes[size.name].push({
                                    name: field.name,
                                    alias: field.alias
                                });
                            }
                        })

                    });
                }
                return sizes;
            }
        },
        methods: {

            getProductTypes() {
                this.$http.get("/api/madetomeasure/product-types").then(x => {
                    this.productTypes = x.data;
                });
            },
            getFields() {
                this.$http.get("/api/madetomeasure/fields").then(x => {
                    this.fields = x.data;
                });
            },
            getProductType(id) {
                this.$http.get("/api/madetomeasure/product-types/" + id).then(x => {
                    this.productType = x.data;
                });
            },
            productTypeModal(item) {
                var config = {
                    title: "Opret produkttype",
                    okTitle: "Opret"
                };
                if (item) {
                    config = { title: "Rediger produkttype", okTitle: "Rediger" };
                }

                this.$dialog.open(productTypeModal, { item: item }, {
                    ...config,
                    onClose: () => {
                        this.getProductTypes()
                    }
                })
            },

            deleteProductType(item) {
                if (confirm("Are you sure you want to delete this product type?")) {
                    this.$http.delete("/api/madetomeasure/product-types/" + item.id).then(x => {
                        this.getProductTypes()
                        this.productType = null
                    });
                }
            },

            copyProductType(item) {
                if (confirm("Are you sure you want to copy this product type?")) {
                    this.$http.post("/api/madetomeasure/product-types/copy/" + item.id).then(x => {
                        this.getProductTypes()
                        this.productType = null
                    });
                }
            },

            setSize(size, o, remove) {
                var alias = o.alias;

                var item = size.fields.find(x => x.fieldAlias == alias);

                if (item) {
                    if (remove === false) {
                        var fieldIndex = size.fields.findIndex(x => x.fieldAlias == alias)
                        size.fields.splice(fieldIndex, 1)
                    } else {
                        if (o.value != null) {
                            item.value = o.value;
                        }
                        if (o.name != null) {
                            item.name = o.name;
                        }
                        if (o.note != null) {
                            item.note = o.note;
                        }
                    }
                } else {
                    size.fields.push({
                        fieldAlias: alias,
                        value: o.value || 0,
                        name: o.name,
                        note: o.note
                    });
                }
            },
            getSize(size, field) {
                var item = size.fields.find(x => x.fieldAlias == field.alias);
                if (item) {
                    return {
                        value: item.value,
                        name: item.name,
                        fieldAlias: item.alias,
                        note: item.note,
                    };
                }
                return {
                    value: null,
                    name: null,
                    note: null,
                    fieldAlias: field.alias,
                };
            },
            hasSize(size, field) {
                var item = size.fields.find(x => x.fieldAlias == field.alias);
                if (item) {
                    return true;
                }
                return false;
            },


            async copySize(size) {
                var sizeCopy = { ...size }
                sizeCopy.sizeId = await this.createId()
                sizeCopy.name = size.name + " (Copy)"

                this.productType.sizes.push(sizeCopy)
            },
            async sizeModal(item, idx) {
                var config = {
                    title: "Create size",
                    okTitle: "Create"
                };
                if (item) {
                    config = { title: "Edit size", okTitle: "Edit" };
                }

                if (item == null) {
                    item = {
                        sizeId: await this.createId(),
                        name: null,
                        fields: []
                    }
                }

                this.$dialog.open(sizeModal, { size: item, sizeIdx: idx, productType: this.productType }, {
                    ...config,
                    onClose: () => {

                    }
                })
            },

            saveSizes() {
                this.$http.post("/api/madetomeasure/product-types/" + this.selected, this.productType).then(x => {
                    this.selected = null;
                    this.productType = null;
                });
            },
        },
        created() {
            this.getProductTypes()
            this.getFields()


        },
        mounted() { }
    };
</script>