import "./style.scss";

import Vue from 'vue'
const routes = [];

const RouterView = { render (c) { return c('router-view') } };

import Invoicing from "./invoicing";

routes.push({ path: "invoicing", component: RouterView, meta: { label: "Fakturering" }, children: [
    { path: "", component: Invoicing, name: "invoicing", meta: { module: "invoicing" } },
] });

export { routes };
