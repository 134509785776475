import "./style.scss";

import Vue from 'vue'

import adjustmentRate from "./areas/adjustmentRate";
Vue.component("adjustmentRate", adjustmentRate);

import deliveryPerformance from "./areas/deliveryPerformance";
Vue.component("deliveryPerformance", deliveryPerformance);

import productionDefects from "./areas/productionDefects";
Vue.component("productionDefects", productionDefects);

import resale from "./areas/resale";
Vue.component("resale", resale);

import segmentedOrders from "./areas/segmentedOrders";
Vue.component("segmentedOrders", segmentedOrders);

import segmentedShowroomOrders from "./areas/segmentedShowroomOrders";
Vue.component("segmentedShowroomOrders", segmentedShowroomOrders);

import segmentedTailorOrders from "./areas/segmentedTailorOrders";
Vue.component("segmentedTailorOrders", segmentedTailorOrders);

import segmentedAccessoryOrders from "./areas/segmentedAccessoryOrders";
Vue.component("segmentedAccessoryOrders", segmentedAccessoryOrders);

import segmentedConnectionOrders from "./areas/segmentedConnectionOrders";
Vue.component("segmentedConnectionOrders", segmentedConnectionOrders);

