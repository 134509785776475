<template>
    <b-card-body>

        <FormGroup label="Navn" scope="module" label-cols-md="3" v-slot:default="props" :error="errors.first('module.name')">
            <b-form-input type="text" v-model="entity.name" :data-vv-scope="props.scope"
                v-validate="'required'" name="name" :data-vv-as="'Navn'" autocomplete="off"></b-form-input>
        </FormGroup>

        <FormGroup label="E-mail" label-cols-md="3" scope="module" v-slot:default="props" :error="errors.first('module.email')">
            <b-form-input type="text" v-model="entity.email" :state="props.state" :data-vv-scope="props.scope"
                v-validate="'required'" name="email" :data-vv-as="'E-mail'" autocomplete="off"></b-form-input>
        </FormGroup>

        
        <FormGroup label="Adgangskode" label-cols-md="3">
            <b-form-input type="text" v-model="entity.password" name="password" autocomplete="off"></b-form-input>
        </FormGroup>

        <FormGroup label="Rolle" label-cols-md="3" v-slot:default="props" scope="module" :error="errors.first('module.roleId')">
            <b-form-select :options="$store.state.roles" v-model="entity.roleId" value-field="alias" text-field="name" 
                :state="props.state" :data-vv-scope="props.scope" v-validate="'required'" name="roleId" :data-vv-as="'Rolle'" autocomplete="off">
            </b-form-select>
        </FormGroup>
        
    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";

export default {
    data: () => ({}),
    components: {},
    computed: {},
    methods: {},
    created() {
    },
    mounted() {},
    mixins: [mixin]
};
</script>