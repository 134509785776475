import "./style.scss";

import Vue from 'vue'
const routes = [];
const RouterView = { render (c) { return c('router-view') } };

import contactDetails from "./areas/contactDetails";
Vue.component("suitsyouContactDetails", contactDetails);

import contactMeasurement from "./areas/contactMeasurement";
Vue.component("suitsyouContactMeasurement", contactMeasurement);

import orderDetails from "./areas/orderDetails";
Vue.component("suitsyouOrderDetails", orderDetails);

import production from "./areas/production";
Vue.component("suitsyouProduction", production);

import productionProducts from "./areas/productionProducts";
Vue.component("suitsyouProductionProducts", productionProducts);

import productionImages from "./areas/productionImages";
Vue.component("suitsyouProductionImages", productionImages);

import productionNotes from "./areas/productionNotes";
Vue.component("suitsyouProductionNotes", productionNotes);


import orderHistory from "./areas/orderHistory";
Vue.component("orderHistory", orderHistory);

import adjustments from "./areas/adjustments";
Vue.component("suitsyouAdjustments", adjustments);

import madeToMeasureWidget from "./areas/madeToMeasure";
Vue.component("madeToMeasure", madeToMeasureWidget);

import contactMadeToMeasure from "./areas/contactMadeToMeasure";
Vue.component("contactMadeToMeasure", contactMadeToMeasure);


import measurementsWidget from "./areas/measurements";
Vue.component("suitsyouMeasurements", measurementsWidget);


import orderContact from "./areas/orderContact";
Vue.component("suitsyouOrderContact", orderContact);

import eventDetails from "./areas/eventDetails";
Vue.component("suitsyouEventDetails", eventDetails);

import productionDashItems from "./areas/productionDashItems";
Vue.component("productionDashItems", productionDashItems);



import Productions from "./productions";
import Production from "./production";
import ProductionDashboard from "./productionDashboard";
import Measurements from "./measurements";
import MadeToMeasure from "./madeToMeasure";

routes.push({ path: "production", component: RouterView, meta: { label: "Production" }, children: [
    { path: "", component: Productions, name: "production", meta: { module: "suitsyou" } },
    { path: "dashboard", component: ProductionDashboard, name: "dashboard", meta: { label: "Overblik", module: "suitsyou" } },
    { path: "madetomeasure", component: MadeToMeasure, name: "madeToMeasure", meta: { label: "Made to measure", module: "suitsyou" } },
    { path: "measurements", component: Measurements, name: "measurements", meta: { label: "Tillægsmål", module: "suitsyou" } },
    { path: ":id", component: Production, name: "productionOrder", meta: { label: "Order", module: "suitsyou" } }
] });


import store from './store';

export { routes, store };
