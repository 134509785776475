<template>
    <b-modal ref="dialogModal" :title="title" :ok-only="okOnly"
             :class="'dialog-modal'" :cancel-title="cancelTitle" :hide-footer="hideFooter"
             :no-close-on-backdrop="noClose" :no-close-on-esc="noClose" :hide-header-close="noClose"
             v-model="dialogModal" :ok-variant="variant" :ok-title="okTitle" @shown="shown"
             :size="size" :centered="centered" @hidden="hidden" @cancel="cancel" @ok="ok">

        <component ref="content" v-if="component" v-bind="props" :is="component" @close="close" @ok="confirm" />
    </b-modal>
</template>

<script>
    export default {
        data: () => ({
            dialogModal: true
        }),
        components: {},
        computed: {},
        watch: {},
        props: {
            "title": String,
            "variant": {
                type: String,
                default: "primary"
            },
            "okTitle": String,
            "cancelTitle": String,
            "okOnly": Boolean,
            "size": String,
            "centered": Boolean,
            "onOk": Function,
            "onClose": Function,
            "onShown": Function,
            "hideFooter": Boolean,
            "noClose": Boolean,
            "component": Object,
            "props": Object
        },
        methods: {
            ok(e) {
                if (this.$refs.content.ok) {
                    e.preventDefault();
                    this.$refs.content.ok();
                } else {
                    if (this.onOk) {
                        this.onOk();
                    }
                }
            },
            cancel(e) {
                if (this.$refs.content.cancel) {
                    e.preventDefault();
                    this.$refs.content.cancel();
                } else {
                    if (this.onClose) {
                        this.onClose();
                    }
                }
            },
            confirm() {
                this.dialogModal = false;

                if (this.onOk) {
                    this.onOk();
                }
            },
            close() {
                this.dialogModal = false;
                if (this.onClose) {
                    this.onClose();
                }
            },
            hidden() {
                if (typeof this.$el.remove !== 'undefined') {
                    this.$el.remove()
                } else if (typeof el.parentNode !== 'undefined') {
                    this.$el.parentNode.removeChild(this.$el)
                }
            },
            shown() {
                if (this.$refs.content.shown) {
                    this.$refs.content.shown();
                }
            }
        },
        created() { },
        mounted() { },
        beforeMount() {
            // Insert the Dialog component in body tag
            this.$nextTick(() => {
                document.body.appendChild(this.$el)
            })
        }
    };
</script>