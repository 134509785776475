<template>
    <b-card-body class="pt-0">
        <div class="row">
            <div class="col-12">
                <b-card no-body class="mb-1" v-if="adjustments">
                    <b-card-header header-tag="header" class="">
                        Produkter
                    </b-card-header>

                    <b-card-body>

                        <table v-for="(line, key) in adjustments" :key="'line-' + key" class="mb-3 table table-sm table-bordered">
                            <tr>
                                <th class="px-2"><h4 class="m-0">{{line.product}}</h4></th>
                                <th class="text-right">
                                    <span class="mr-2">Årsag: </span>
                                    <div style="display: inline-block; width: 250px;">
                                        <multiselect :value="getReason(line)" @input="updateReason($event, line)"
                                            :options="reasons" :multiple="false" :allow-empty="true"
                                            autocomplete="off" :show-labels="false" :searchable="true"></multiselect>
                                    </div>
                                    <div v-if="getReason(line) == 'Andet'" style="display: inline-block; width: 200px;">
                                        <b-form-input type="text" autocomplete="off" placeholder="Årsag note"
                                            :value="getReasonNote(line)" @input="updateReasonNote($event, line)"></b-form-input>
                                    </div>
                                </th>
                            </tr>

                            <tr v-if="line.adjustments">
                                <td colspan="2" class="px-2">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6 col-md-4 mb-1 mt-1" v-for="(field, fieldKey) in line.adjustments" :key="'adjustment-' + fieldKey">
                                            <div class="row">
                                                <label class="col-sm-7 col-md-7 col-lg-6 col-xl-5 col-6 font-weight-bold">{{field.name}}</label>
                                                <div class="col" style="min-width: 45px">{{field.value}}</div>
                                                <div class="col" style="min-width: 45px">
                                                    <b-form-spinbutton @input="updateAdjustment($event, line, field)" :value="getAdjustment(line, field)" min="-100" max="100" style="padding: 4px 4px 4px 4px; text-align: center;"></b-form-spinbutton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </table>
                    </b-card-body>
                </b-card>
            </div>
        </div>
    </b-card-body>
</template>

<script>
import mixin from "../../mixins/area";

export default {
    data: () => ({
        adjustments: null,
        reasons: [
            'Opmåling kontra kundeønske', 
            'Markant vægtforandring', 
            'Tastefejl',
            'Produktionsfejl',
            'Umulig kunde',
            'Andet'
        ]
    }),
    components: {},
    watch: {
        "entity.orderLines": {
            handler(lines) {
                if (lines && this.adjustments) {
                    var deletes = [];
                    this.adjustments.forEach((x,i) => {
                        var line = lines.find(e => e.productId == x.productId && (e.variantId == x.variantId || (!e.variantId && x.variantId == "")));
                        if (!line) {
                            deletes.push(i);
                        }
                    });

                    deletes.forEach(i => {
                        this.adjustments.splice(i, 1);
                    })
                }
            },
            deep: true
        }
    },
    computed: {},
    methods: {
        getAdjustments() {
            this.$http.get("/api/module/suitsyou/adjustments/" + this.entity.Id).then(x => {
                this.adjustments = x.data;

                if (this.entity.adjustments) {
                    var deletes = [];

                    this.entity.adjustments.forEach((x, i) => {
                        var fieldDel = [];

                        var adjustmentProduct = this.adjustments.find(e => e.productId == x.productId && e.variantId == x.variantId);
                        if (adjustmentProduct) {
                            var fields = x.fields.forEach((f, fi) => {
                                var adjustment = adjustmentProduct.fields.find(e => e.fieldAlias == f.fieldAlias && e.name == f.name.toLowerCase());
                                if (!adjustment) {
                                    fieldDel.push(fi);
                                }
                            });
                            fieldDel.forEach(i => {
                                adjustmentProduct.fields.splice(i, 1);
                            })
                        }
                        else
                        {
                            deletes.push(i);
                        }
                    });

                    deletes.forEach(i => {
                        this.entity.adjustments.splice(i, 1);
                    })
                }
            });
        },
        getReason(product) {
            if (!this.entity.adjustments) {
                return null
            }

            var adjustmentProduct = this.entity.adjustments.find(x => x.productId == product.productId && x.variantId == product.variantId)
            if (adjustmentProduct) {
                return adjustmentProduct.reason
            }
            return null
        },
        updateReason(e, product) {
            var adjustmentProduct = this.entity.adjustments.find(x => x.productId == product.productId && x.variantId == product.variantId)
            if (adjustmentProduct) {
                adjustmentProduct.reason = e
            } else {
                this.entity.adjustments.push({
                    productId: product.productId,
                    variantId: product.variantId,
                    reason: e,
                    reasonNote: "",
                    fields: []
                })
            }
        },
        getReasonNote(product) {
            if (!this.entity.adjustments) {
                return null
            }

            var adjustmentProduct = this.entity.adjustments.find(x => x.productId == product.productId && x.variantId == product.variantId)
            if (adjustmentProduct) {
                return adjustmentProduct.reasonNote
            }
            return null
        },
        updateReasonNote(e, product) {
            var adjustmentProduct = this.entity.adjustments.find(x => x.productId == product.productId && x.variantId == product.variantId);
            if (adjustmentProduct) {
                adjustmentProduct.reasonNote = e;
            } else {
                this.entity.adjustments.push({
                    productId: product.productId,
                    variantId: product.variantId,
                    reason: 'Andet',
                    reasonNote: e,
                    fields: []
                });
            }
        },
        getAdjustment(product, field) {
            if (!this.entity.adjustments) {
                return 0
            }

            var adjustmentProduct = this.entity.adjustments.find(x => x.productId == product.productId && x.variantId == product.variantId);
            if (adjustmentProduct) {
                var adjustment = adjustmentProduct.fields.find(x => x.fieldAlias == field.fieldAlias && x.name == field.name.toLowerCase());
                if (adjustment) {
                    return adjustment.value;
                }
            }
            return 0;
        },
        updateAdjustment(e, product, field) {
            var adjustmentProduct = this.entity.adjustments.find(x => x.productId == product.productId && x.variantId == product.variantId);
            if (adjustmentProduct) {
                var adjustment = adjustmentProduct.fields.find(x => x.fieldAlias == field.fieldAlias && x.name == field.name.toLowerCase());
                if (adjustment) {
                    adjustment.value = e;
                } 
                else
                {
                    adjustmentProduct.fields.push({
                        fieldAlias: field.fieldAlias,
                        value: e,
                        name: field.name.toLowerCase(),
                        measurement: field.value
                    });
                }
            } 
            else
            {
                this.entity.adjustments.push({
                    productId: product.productId,
                    variantId: product.variantId,
                    reason: "",
                    reasonNote: "",
                    fields: [{
                        fieldAlias: field.fieldAlias,
                        value: e,
                        name: field.name.toLowerCase(),
                        measurement: field.value
                    }]
                });
            }
        }
    },
    created() {
        if (!this.entity.adjustments) {
            this.$set(this.entity, "adjustments", []);
        }

        if (this.bus && this.entity && this.entity.Id) {
            this.getAdjustments();

			this.bus.$on('submit', this.getAdjustments);
		}

    },
    mounted() {},
    mixins: [mixin]
};
</script>