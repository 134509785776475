<template>
	<div>
		<TextEditor :entity="field" label="Navn" alias="5d13b72af7cb1732200f1c5d" :validate="true" scope="field"></TextEditor>

		<FormGroup label="Type" label-cols-md="3">
			<b-form-select :options="editorTypes" v-model="field['5d13b746f7cb1732200f1c5e']" class="">
				<template slot="first">
					<option :value="null"></option>
				</template>
			</b-form-select>
		</FormGroup>

		<TextEditor :entity="field" label="Standard værdi" alias="5d13b776f7cb1732200f1c5f" :validate="false" scope="field"></TextEditor>
		
        <FormGroup label="Valgmuligheder" scope="module" label-cols-md="3">		
			<multiselect 
				class="optify-select"
				:options="fieldValues" 
				:multiple="true"
				:taggable="true"
				v-model="tagValues"
				@tag="addTag"
				:close-on-select="false">
			</multiselect>
		</FormGroup>


		<FormGroup label="Lagerstyring" label-cols-md="3">
			<b-form-select :options="stockTypes" v-model="field.stockType" class="">
				<template slot="first">
					<option :value="null">Ingen</option>
				</template>
			</b-form-select>
		</FormGroup>
		
        <b-form-checkbox v-model="field.required">
            Påkrævet felt
        </b-form-checkbox>

        <b-form-checkbox v-model="field.invoicing" class="mt-3 mb-1">
            Fakturering
        </b-form-checkbox>

		<TextEditor v-if="field.invoicing" :entity="field" label="Tilvalg gruppe" alias="invoiceName" :validate="false" scope="field"></TextEditor>

        <FormGroup v-if="field.invoicing" label="Muligheder" scope="module" label-cols-md="3">		
			<multiselect 
				class="optify-select"
				:options="tagValues" 
				:multiple="true"
				:taggable="false"
				v-model="field.invoiceValues"
				:close-on-select="false">
			</multiselect>
		</FormGroup>

	</div>
</template>

<script>
import TextEditor from "../../_ui/editors/text";
import SelectEditor from "../../_ui/editors/select";

export default {
	data: () => ({
		fieldValues: [],
		tagValues: [],
		editorTypes:[
			{ text: "Læs kun", value: "readonly" },
			{ text: "Tekst", value: "text" },
			{ text: "Lang tekst", value: "rich-text" },
			{ text: "Tal", value: "number" },
			{ text: "Valgmuligheder", value: "select" },
			{ text: "Udregning", value: "calculation" }
		],
		stockTypes: []
	}),
	components: { TextEditor, SelectEditor },
	watch: {
		tagValues(val) {			
			this.field['5d13b7cbf7cb1732200f1c60'] = val;
		}
	},
	computed: {},
	props: ["fields", "field"],
	methods: {
		addTag(value) {
			this.fieldValues.push(value);
			this.tagValues.push(value);
		},
		ok() {
			this.$validator.validate("field.*").then(valid => {
				if (valid) {
					var item = this.fields.findIndex(x => x.id == this.field.id);
					if (item != -1) {
						this.fields.splice(item, 1, this.field);
					} else {
						this.fields.push(this.field);
					}

					this.$emit("close");
				}
			})
		},
		cancel() {
			this.$emit("close");
		}
	},
	created() {
		if (this.field['5d13b7cbf7cb1732200f1c60'] != null) {
			this.fieldValues = this.field['5d13b7cbf7cb1732200f1c60'];
			this.tagValues = this.field['5d13b7cbf7cb1732200f1c60'];
		}

		this.$http.get("/api/stock/types").then(x => {
			this.stockTypes = x.data
        })
	},
	mounted() {},
};
</script>