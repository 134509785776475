<template>
    <div>
        <b-card-body v-if="entity.orderLines">

            <table class="table b-table orderlines-table table-striped b-table-stacked-md">
                <thead>
                    <tr>
                        <th class="px-1 py-2">Produkt</th>
                        <th class="px-1 py-2">Pris u. moms</th>
                        <th class="px-1 py-2">Pris m. moms</th>
                        <th class="px-1 py-2">Rabat m. moms</th>
                        <th class="px-1 py-2">Total pris</th>
                        <th class="px-1 py-2 text-right" style="min-width: 104px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(row, rowKey) in entity.orderLines">
                        <tr :key="'row-' + rowKey" :class="{ 'table-danger': lineErrors[rowKey] }">
                            <td class="px-1 py-2">
                                <b-dropdown boundary=".orderlines-table" class="products-dropdown" no-caret toggle-tag="div" :ref="'dropdown' + rowKey" @shown="ddShow(row, rowKey)" @hide="ddHide(rowKey, $event)">
                                    <template slot="button-content">
                                        <b-form-input :ref="'dd-product' + rowKey" @keyup="getProducts(row.product)" @focus="ddShow(row, rowKey, $event)" type="text" v-model="row.product" autocomplete="off"></b-form-input>
                                    </template>
                                    <b-dropdown-text>
                                        <table class="table b-table-stacked-lg">
                                            <tr v-for="product in products" :key="product.id">
                                                <td class="text-nowrap">{{product.name}}</td>
                                                <td style="min-width: 160px">
                                                    <b-form-select v-model="product.selectedVariant" :options="product.variants" v-if="product.variants && product.variants.length > 0"
                                                                   text-field="5ce2e8e046cd0030dcf950e0" value-field="id" class=""></b-form-select>
                                                </td>
                                                <td class="text-nowrap" v-if="product.variants && product.variants.find(x => x.id == product.selectedVariant) && product.variants.find(x => x.id == product.selectedVariant)['5ce2e90346cd0030dcf950e3']">
                                                    {{$numeral(product.variants.find(x => x.id == product.selectedVariant)['5ce2e90346cd0030dcf950e3']).format("#,#.00")}} DKK
                                                </td>
                                                <td v-else class="text-nowrap">{{$numeral(product.price).format("#,#.00")}} DKK</td>
                                                <td><b-button @click="selectProduct(product, row, rowKey)" variant="primary" size="sm"><i class="fa fa-check"></i></b-button></td>
                                            </tr>
                                        </table>
                                    </b-dropdown-text>
                                    <b-dropdown-item><i class="fa fa-plus"></i> Opret nyt produkt</b-dropdown-item>
                                </b-dropdown>
                                <span v-if="row.productId" @click="removeSelectedProduct(row, $event)" class="fa fa-times fa-lg text-danger"></span>
                            </td>
                            <td class="px-1 py-2">
                                <b-form-input type="text" v-model="row.price" @change="updatePrice(row)" autocomplete="off" v-secret="entity.ClientId"></b-form-input>
                            </td>
                            <td class="px-1 py-2">
                                <b-form-input type="text" v-model="row.priceWithVat" @change="updatePriceWithVat(row)" autocomplete="off" v-secret="entity.ClientId"></b-form-input>
                            </td>
                            <td class="px-1 py-2">
                                <b-form-input type="text" v-model="row.discount" @input="updatePrice(row)" autocomplete="off" v-secret="entity.ClientId"></b-form-input>
                            </td>
                            <td class="px-1 py-2">
                                <span class="text-nowrap" v-secret="entity.ClientId">{{$numeral(row.totalPrice).format("#,#.00")}} DKK</span>
                            </td>
                            <td class="px-1 py-2 text-right">
                                <b-button @click="toggleDetails(rowKey)" variant="primary"><i class="fa fa-ellipsis-h"></i></b-button>

                                <b-button @click="removeOrderline(rowKey)" variant="danger"><i class="fa fa-trash"></i></b-button>
                            </td>
                        </tr>
                        <tr :key="'row-template-' + rowKey" v-show="rowDetails == rowKey">
                            <td colspan="6" class="px-0 py-1">
                                <b-card class="m-0">
                                    <div class="row">
                                        <div class="col-md-6 col-lg-3">
                                            <FormGroup label="Variant">
                                                <b-form-input type="text" v-model="row.variant" autocomplete="off"
                                                              :disabled="row.productId != null"></b-form-input>
                                            </FormGroup>
                                        </div>
                                        <div class="col-md-6 col-lg-3">
                                            <FormGroup label="Vægt">
                                                <b-form-input type="text" :value="row.weight" :disabled="row.productId != null"
                                                              @input="x => row.weight = $numeral(x).value()" autocomplete="off"></b-form-input>
                                            </FormGroup>
                                        </div>
                                        <div class="col-md-6 col-lg-3">
                                            <FormGroup label="SKU">
                                                <b-form-input type="text" v-model="row.sku" autocomplete="off"
                                                              :disabled="row.productId != null"></b-form-input>
                                            </FormGroup>
                                        </div>
                                        <div class="col-md-6 col-lg-3">
                                            <FormGroup label="Producent">
                                                <b-form-input type="text" v-model="row.brand" autocomplete="off"
                                                              :disabled="row.productId != null"></b-form-input>
                                            </FormGroup>
                                        </div>
                                    </div>

                                    <div class="row" v-if="getProductSpec(row.productId)">
                                        <template v-for="field in getProductSpec(row.productId).fields">
                                            <div class="col-md-6 col-lg-4" v-if="field.stockType && !field.deleted" :key="field.id + '-' + rowKey">
                                                <component :is="'editor-' + field['5d13b746f7cb1732200f1c5e']" :entity="row.specs" searchable
                                                           :label="field['5d13b72af7cb1732200f1c5d']" scope="module" :alias="field.id" :name="field.id + '-orderline-' + rowKey" @change="updateStock($event, row)"
                                                           :validate="field.required" :values="stockValues(field.stockType, row)" :default="field['5d13b776f7cb1732200f1c5f']"></component>
                                            </div>
                                            <div class="col-md-6 col-lg-4" v-else-if="!field.deleted" :key="field.id + '-' + rowKey">
                                                <component :is="'editor-' + field['5d13b746f7cb1732200f1c5e']" :entity="row.specs" searchable
                                                           :label="field['5d13b72af7cb1732200f1c5d']" scope="module" :alias="field.id" :name="field.id + '-orderline-' + rowKey" @update="specPriceUpdate($event, row)"
                                                           :validate="field.required" :values="field['5d13b7cbf7cb1732200f1c60']" :default="field['5d13b776f7cb1732200f1c5f']"></component>
                                            </div>
                                        </template>
                                    </div>
                                </b-card>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <div class="total-container">
                <div class="total">
                    <div>
                        <div class="float-left">Subtotal</div>
                        <div class="float-right" v-secret="entity.ClientId">
                            {{$numeral(totals.subTotal).format("0,0.00 $")}}
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div>
                        <div class="float-left">Moms (25,00%)</div>
                        <div class="float-right" v-secret="entity.ClientId">
                            {{$numeral(totals.vat).format("0,0.00 $")}}
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div v-if="totals.discount > 0">
                        <div class="float-left">Rabat</div>
                        <div class="float-right" v-secret="entity.ClientId">
                            -{{$numeral(totals.discount).format("0,0.00 $")}}
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="last">
                        <div class="float-left">Total DKK</div>
                        <div class="float-right" v-secret="entity.ClientId">
                            {{$numeral(totals.total).format("0,0.00 $")}}
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>

        </b-card-body>
        <b-card-footer>
            <b-button @click="addOrderLine" size="sm" variant="primary"><span class="fa fa-plus pr-1"></span> Tilføj produkt</b-button>
        </b-card-footer>
    </div>
</template>

<script>
    import mixin from "../../mixins/area";
    import Editors from "../../mixins/dynamicEditors";

    export default {
        data: () => ({
            products: [],
            productSpec: null,
            rowDetails: null,
            productSpecs: [],
            stock: [],

            lineErrors: {}
        }),
        inject: ['$validator'],
        watch: {
            totals(val) {
                this.entity.subtotal = val.subTotal;
                this.entity.discount = val.discount;
                this.entity.vat = val.vat;
                this.entity.total = val.total;
            },
            "entity.orderLines": {
                handler() {
                    this.getOrderSpecs();
                },
                deep: true
            },
            "$validator.errors.items": {
                handler(val) {
                    var errors = val.filter(x => x.field.split('-')[1] == 'orderline')
                    this.lineErrors = {}
                    errors.forEach(e => {
                        var rowKey = e.field.split('-')[2]
                        this.lineErrors[rowKey] = true
                    })
                },
                deep: false
            }
        },
        components: {
            ...Editors
        },
        computed: {
            creating() {
                return this.$route.params.id == "create";
            },
            id() {
                return this.$route.params.id;
            },
            totals() {
                var subTotal = 0;
                var vat = 0;
                var discount = 0;
                var total = 0;

                if (this.entity.orderLines) {
                    this.entity.orderLines.forEach(x => {
                        var price = this.$numeral(x.price).value() * x.quantity;
                        var totalPrice = this.$numeral(x.totalPrice).value();

                        var priceVat = totalPrice * 0.8;
                        subTotal += priceVat;
                        vat += (totalPrice * 0.2);
                        discount += this.$numeral(x.discount).value();
                    });
                }

                total = (subTotal + vat);

                return {
                    subTotal: subTotal,
                    discount: discount,
                    vat: vat,
                    total: total
                };
            }
        },
        methods: {
            getOrderSpecs() {
                if (this.entity.orderLines) {
                    var productIds = this.entity.orderLines.filter(x => x.productId != null).map(x => x.productId);
                    this.$http.post("/api/module/orders/productspecs", productIds).then(x => {
                        this.productSpecs = x.data
                    });

                    var lineProducts = this.entity.orderLines.filter(x => x.productId != null).map(x => { return { ProductId: x.productId } }); // , VariantId: x.variantId
                    this.$http.post("/api/stock/orderlines", lineProducts).then(x => {
                        this.stock = x.data
                    });
                }
            },
            addOrderLine() {
                this.entity.orderLines.push({
                    product: null,
                    productId: null,
                    variantId: null,
                    sku: null,
                    quantity: 1,
                    price: null,
                    priceWithVat: null,
                    discount: 0,
                    totalPrice: null,
                    weight: 0,
                    specs: {},
                    stock: {}
                });
            },
            removeOrderline(idx) {
                if (confirm("Er du sikker på at du vil fjerne denne ordrelinje?")) {
                    this.entity.orderLines.splice(idx, 1);
                }
            },

            updateStock(name, orderLine) {
                var stockItem = this.stock.find(x => x.name == name && x.productId == orderLine.productId) // && x.variantId == orderLine.variantId
                if (stockItem) {
                    var amount = this.$numeral(stockItem.spend).multiply(orderLine.quantity).value()
                    orderLine.stock[stockItem.type] = { [stockItem.id]: amount }
                }
                return stockItem
            },
            stockValues(type, orderLine) {
                var reservedStock = this.entity.orderLines.filter(x => x.stock != null).map(e => e.stock[type]).filter(x => x)

                var stockItems = this.stock.filter(x =>
                    x.type == type &&
                    x.productId == orderLine.productId &&
                    //x.variantId == orderLine.variantId &&
                    this.$numeral(x.stockAmount).value() >= this.$numeral(x.spend).multiply(orderLine.quantity).add(
                        reservedStock.map(e => e[x.id]).reduce((partialSum, a) => partialSum + this.$numeral(a).value(), 0)
                    ).value()
                )

                return stockItems.map(x => x.name)
            },
            updateQuantity(orderLine) {
                var specs = orderLine.specs
                if (orderLine.productId) {
                    var productSpecs = this.productSpecs.find(x => x.Id == orderLine.productId)
                    if (productSpecs) {
                        var fields = productSpecs.fields.filter(x => x.stockType)

                        fields.forEach(x => {
                            var stockFieldName = specs[x.id]
                            var stockItem = this.updateStock(stockFieldName, orderLine)
                            if (stockItem) {
                                var reservedStockTypes = this.entity.orderLines.map(e => e.stock[stockItem.type]).filter(x => x)
                                var reservedStock = reservedStockTypes.map(e => e[stockItem.id]).reduce((partialSum, a) => partialSum + this.$numeral(a).value(), 0)

                                var stockAmount = this.$numeral(stockItem.stockAmount).value()
                                if (reservedStock > stockAmount) {
                                    specs[x.id] = null
                                    delete orderLine.stock[stockItem.type][stockItem.id]

                                    alert("Det valgte antal er ikke tilgængeligt for " + stockFieldName + " og er derfor blevet fjernet!")
                                }
                            }
                        });
                    }
                }
            },

            ddShow(item, index, e) {
                this.$refs['dd-product' + index][0].focus();
                if (item.product) {
                    this.getProducts(item.product);
                }

                this.$nextTick();
            },
            ddHide(index, e) {
                if (document.activeElement.id == this.$refs['dd-product' + index][0].$el.id) {
                    e.preventDefault();
                } else {
                    this.products = [];
                }
            },
            getProductFilter() {

            },
            getProducts(value) {
                var filterField = this.module.settings.find(x => x.alias == "productFilterField");
                var filterOrderField = this.module.settings.find(x => x.alias == "productFilterOrderField");

                var filter = null;

                if (filterField && filterOrderField) {
                    filter = {
                        field: filterField.value,
                        value: this.entity[filterOrderField.value]
                    }
                }

                this.$http.post("/api/module/orders/products", filter, {
                    params: {
                        search: value
                    }
                }).then(x => {
                    this.products = x.data;
                    this.products.forEach(x => {
                        if (x.variants && x.variants.length > 0) {
                            this.$set(x, "selectedVariant", x.variants[0].id)
                        }
                    })
                });
            },

            selectProduct(product, item, index) {
                item.product = product.name;
                item.productId = product.Id;
                item.variantId = product.selectedVariant;
                item.sku = product.sku;
                item.quantity = 1;
                item.weight = product.weight;
                item.brand = product.brand;
                if (product.price) {
                    item.price = this.$numeral(product.price).format("#.#");
                } else {
                    item.price = 0;
                }
                if (product.selectedVariant) {
                    var variant = product.variants.find(x => x.id == product.selectedVariant);
                    item.variant = variant["5ce2e8e046cd0030dcf950e0"];
                    item.sku = variant["5ce2e8ef46cd0030dcf950e1"];
                    if (variant["5ce2e90346cd0030dcf950e3"]) {
                        item.price = this.$numeral(variant["5ce2e90346cd0030dcf950e3"]).format("#.#");
                    }
                }
                item.discount = 0;
                item.priceWithVat = this.$numeral(this.$numeral(item.price).value() * 1.25).format("#.#");
                item.totalPrice = item.priceWithVat;

                this.$refs['dropdown' + index][0].hide();

                this.getOrderSpecs();
            },
            removeSelectedProduct(item, e) {
                e.stopPropagation();

                item.productId = null;
                item.variantId = null;
                item.variant = null;
                item.sku = null;
            },
            updatePrice(item) {
                var quantity = item.quantity;
                var price = this.$numeral(item.price).value();

                var priceWithVat = price * 1.25;
                var discount = this.$numeral(item.discount).value();

                var specs = item.specs;
                var specPrice = 0;
                if (item.productId) {
                    var productSpecs = this.productSpecs.find(x => x.Id == item.productId);
                    if (productSpecs) {
                        var fields = productSpecs.fields.filter(x => x["5d13b746f7cb1732200f1c5e"] == "calculation");


                        fields.forEach(x => {
                            var fieldId = x.id;
                            var calcField = specs[fieldId];
                            var calcValue = this.$numeral(calcField).value();

                            specPrice += calcValue;
                        });
                    }
                }

                var totalPrice = (priceWithVat + specPrice - discount) * quantity;

                item.price = this.$numeral(price).format("#.#");
                item.priceWithVat = this.$numeral(priceWithVat).format("#.#");
                item.totalPrice = this.$numeral(totalPrice).format("#.#");
            },
            updatePriceWithVat(item) {
                var priceWithVat = this.$numeral(item.priceWithVat).value();
                var price = priceWithVat * 0.8;

                item.price = this.$numeral(price).format("#.#");

                this.updatePrice(item);
            },

            toggleDetails(index) {
                if (this.rowDetails == index) {
                    this.rowDetails = null
                } else {
                    this.rowDetails = index
                }
            },
            specPriceUpdate(val, line) {
                this.updatePrice(line);
            },
            getProductSpec(productId) {
                return this.productSpecs.find(x => x.Id == productId)
            }
        },
        created() {
            if (!this.entity.orderLines) {
                this.$set(this.entity, "orderLines", []);
            }

            this.getOrderSpecs();
        },
        mounted() { },
        mixins: [mixin]
    };
</script>