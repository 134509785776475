<template>
    <div class="position-relative has-tool-menu">
		
		<div class="tool-menu">
			<Breadcrumb />

			<div class="buttons">
				<ButtonAreas />

				<b-button v-if="module.access >= 30" variant="success" append :to="'create'"><span class="fa fa-plus"></span> Opret bruger</b-button>
			</div>
		</div>

		<b-card no-body>
			<div slot="header">
				<i class="fa fa-users"></i> Brugere
				<div class="card-header-actions">
					<b-link href="#" class="card-header-action btn-setting">
						<i class="fa fa-cog"></i>
					</b-link>
				</div>
			</div>
			<b-collapse id="collapse-users" visible>
                <DataTable ref="dataTable" v-if="fields" url="/api/module/users" :fields="fields" name="users" cache-search="users" :filter="true">
                    <template slot="name" slot-scope="{props}">
                        <b-link append :to="props.row.Id">
                            {{props.row.name}}
                        </b-link>
                    </template>
					<template slot="CreatedOn" slot-scope="{props}">
						{{$moment(props.row.CreatedOn).format("DD-MM-YYYY HH:mm")}}
					</template>
					<template slot="edit" slot-scope="{props}">
                        <b-button append :to="props.row.Id" variant="primary"><i class="fa fa-eye"></i> Se mere</b-button>
						<b-button @click="deleteUser(props.row)" variant="danger">Slet</b-button>
					</template>
				</DataTable>
			</b-collapse>
		</b-card>
	</div>
</template>

<script>

export default {
	data: () => ({
		fields: null
	}),
	components: {},
	computed: {},
	methods: {
        deleteUser(user) {
			if (confirm("Er du sikker på at du vil slette " + user.Name + "?")) {
                this.$http.delete("/api/module/users/user/" +  user.Id).then(x => {
                    this.$refs.dataTable.refresh();
                });
			}
        }
	},
	created() {
		this.$http.get("/api/module/users/fields").then(x => {			
			this.fields = x.data;

			this.fields.push({
				name: "edit",
				visible: true,
				label: "",
				class: "text-right",
			});
		});
	},
	mounted() {}
};
</script>